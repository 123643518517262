<!-- <app-navbar-style-one></app-navbar-style-one> --> 
<div class="row navbarMobil" style="padding-top: 120px;">
    <div class="search-overlay-form">
        <form>
            <input type="text" class="input-search" placeholder="Ara...">
            <button type="submit"><i class='bx bx-search'></i></button>
        </form>
    </div>
</div>

<app-homeone-banner></app-homeone-banner>

<section>
    <app-brands-slider></app-brands-slider>
</section>
<section class="products-area ptb-100 bg-f5f5f5">
    <app-upcoming-products></app-upcoming-products>
</section>

<section class="products-area pb-100 pt-100">
    <app-trending-products></app-trending-products>
</section>

<app-facility-style-one></app-facility-style-one>

<section class="products-area ptb-100">
    <app-popular-products></app-popular-products>
</section>

<app-testimonials></app-testimonials>

<app-blog></app-blog>

<app-partner></app-partner>