import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { apiConst } from 'src/app/constants/apiConst';
import { CartApiModel, CartData, CartDataApiModel, CartModel, CartTotalModel } from 'src/app/models/cartmodel';
import { AlertService } from 'src/app/services/alertService';
import { CartService } from 'src/app/services/cartService';
import { StorageService } from 'src/app/services/reactiveStorageService';

@Component({
    selector: 'app-cart-page',
    templateUrl: './cart-page.component.html',
    styleUrls: ['./cart-page.component.scss']
})
export class CartPageComponent implements OnInit {
    cartTotalModel: CartTotalModel = { products: null, totalPrice: 0 };
    cartModelList: CartModel[];
    cartApiModel: CartApiModel;

    cartDataApiModel: CartDataApiModel;
    cartData: CartData;

    customerId: string;

    constructor(private alertService: AlertService, private router: Router, private localStorage: StorageService, private spinner: NgxSpinnerService, private cartService: CartService) { }

    ngOnInit(): void {
        this.customerId = this.localStorage.get(apiConst.customerId);
        if (this.customerId == null) {
            this.router.navigate(['/login'])
            return;
        }
        this.spinner.show();
        this.getCustomerCart();
    }

    pageTitle = [
        {
            bgImage: 'https://eirc.com.tr/assets/img/65f4cdda-6fa0-43fa-b2e0-165c2d7bb1a4.jpg',
            title: 'Sepet'
        }
    ]

    getCustomerCart() {
        this.cartService.CartGetByCustomerId(this.customerId).subscribe({
            next: datas => {
                this.spinner.hide();
                this.cartApiModel = datas.data;
                this.cartModelList = this.cartApiModel.cartsData
                this.cartModelList.forEach((element) => {
                    element.photoString = "https://eirc.com.tr/assets/img/" + element.photoString
                })
                this.cartTotalModel.products = this.cartModelList;
                this.calculateTotalPrice();
            }
        })
    }

    calculateTotalPrice() {
        this.cartTotalModel.totalPrice = 0;
        this.cartTotalModel.products.forEach((element) => {
            this.cartTotalModel.totalPrice += element.productTotalPrice
        })
    }

    updateProductQuantity(index: number, quantity: number, cartId: string) {
        this.spinner.show();
        this.cartService.UpdateCart(cartId, quantity).subscribe({
            next: datas => {
                this.cartDataApiModel = datas.data;
                this.cartData = this.cartDataApiModel.cartData
                this.getCustomerCart();
            }
        })
    }

    deleteProduct(event: Event, index: number, cartId: string, cartName: string) {
        this.alertService.DeleteAlert(cartName).subscribe({
            next: response => {
                if (response) {
                    this.cartService.DeleteCartItem(cartId).subscribe({
                        next: response => {
                            this.alertService.DeletedAlert(response);
                            if (response.isSuccess) {
                                event.preventDefault();
                                this.cartTotalModel.products.splice(index, 1)
                                this.calculateTotalPrice();
                            }
                        }
                    })
                }
            }
        })
    }
}