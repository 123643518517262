import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { apiConst } from 'src/app/constants/apiConst';
import { optionMapConst } from 'src/app/constants/optionMapConst';
import { SiteInformationApiModel, SiteInformation } from 'src/app/models/siteInformationModel';
import { SiteInformationService } from 'src/app/services/siteInformation';

@Component({
  selector: 'app-return-policy',
  templateUrl: './return-policy.component.html',
  styleUrls: ['./return-policy.component.scss']
})
export class ReturnPolicyComponent implements OnInit {

  siteInformationApiModel: SiteInformationApiModel
  siteInformationList: SiteInformation[] = [];
  photoString: string;
  pageText: string;

  constructor(private spinner: NgxSpinnerService, private siteInformationService: SiteInformationService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.siteInformationService.GetSiteInformationByType({ type: [optionMapConst.returnPolicy], userId: apiConst.userId }).subscribe({
      next: datas => {
        let siteInformationApiModel: SiteInformationApiModel
        siteInformationApiModel = datas.data;
        this.pageText = siteInformationApiModel?.siteInformationsData[0]?.siteInformationPhotos[0]?.text;
        this.photoString = siteInformationApiModel.siteInformationsData[0].siteInformationPhotos[0].photoString;
        this.photoString = apiConst.imageUrl + this.photoString;
        this.spinner.hide();
      }
    })
  }
}
