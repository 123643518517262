<!-- <app-navbar-style-one></app-navbar-style-one> -->

<!-- Start Page Title Area -->
<div class="page-title-area"
    style="background-image: url({{this.siteInformationList[0]?.siteInformationPhotos[0]?.photoString}});">
    <h1>{{this.siteInformationList[0]?.siteInformationPhotos[0]?.title}}</h1>
    <ul>
        <li><a routerLink="/">Ana Sayfa</a></li>
        <li>{{this.siteInformationList[0]?.siteInformationPhotos[0]?.title}}</li>
    </ul>
</div>
<!-- End Page Title Area -->

<!-- Start Products Area -->
<section class="products-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area left-widget-area">
                    <div class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <input type="search" class="search-field" placeholder="Ara..." [(ngModel)]="searchTerm">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">İlgİlİ KAtegorİler</h3>
                        <ul>
                            <li *ngIf="subcategoriesData?.length == 0">
                                <a class="currentCategory">{{categoryName}}</a>
                            </li>
                            <li *ngFor="let item of subcategoriesData">
                                <a [routerLink]="['/ürünler',item.id,item.name]">{{item.name}}</a>
                            </li>
                        </ul>
                    </div>

                    <div class="widget widget_colors" *ngIf="categoriesPropertiesData?.length != 0">
                        <h3 class="widget-title">fİltrele</h3>
                        <ul>

                            <li *ngFor="let item of categoriesPropertiesData">
                                <a href="javascript:void(0)">
                                    {{item.name}}
                                </a>
                            </li>

                        </ul>
                    </div>

                    <div class="widget widget_price_filter">
                        <h3 class="widget-title">Fiyata Göre filtrele</h3>

                        <div class="collection_filter_by_price">
                            <input class="js-range-of-price" type="text" data-min="0" data-max="1055"
                                name="filter_by_price" data-step="10">
                        </div>
                    </div>

                    <!-- <div class="widget widget_top_rated_products">
                        <h3 class="widget-title">En Çok Satanlar</h3>

                        <article class="item">
                            <a routerLink="/slider-product" class="thumb"><span class="fullimage cover bg1"
                                    role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/slider-product">Long Sleeve Leopard</a></h4>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="price">$150.00</span>
                            </div>
                        </article>

                        <article class="item">
                            <a routerLink="/slider-product" class="thumb"><span class="fullimage cover bg2"
                                    role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/slider-product">Causal V-Neck Soft</a></h4>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star-half'></i>
                                </div>
                                <span class="price">$150.00</span>
                            </div>
                        </article>
                        <article class="item">
                            <a routerLink="/slider-product" class="thumb"><span class="fullimage cover bg3"
                                    role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/slider-product">Yidarton Women's Comfy</a></h4>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bx-star'></i>
                                </div>
                                <span class="price">$150.00</span>
                            </div>
                        </article>

                        <article class="item">
                            <a routerLink="/slider-product" class="thumb"><span class="fullimage cover bg4"
                                    role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/slider-product">Womens Tops Color</a></h4>
                                <div class="star-rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bx-star'></i>
                                    <i class='bx bx-star'></i>
                                </div>
                                <span class="price">$150.00</span>
                            </div>
                        </article>
                    </div> -->
                </aside>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="fiwan-grid-sorting row align-items-center">
                    <div class="col-lg-6 col-md-6 result-count">
                        <div class="d-flex align-items-center">
                            <p>Sizin için uygun <span class="count">{{companyProductData?.length}}</span> ürün bulduk
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 ordering">
                        <div class="select-box">
                            <label>Sırala:</label>
                            <ngx-select-dropdown (searchChange)="searchChange($event)" tabindex="0" [multiple]="false"
                                [(ngModel)]="singleSelect" [config]="config" [options]="options"></ngx-select-dropdown>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-sm-6 col-md-6"
                        *ngFor="let Content of companyProductData |filter: searchTerm|  paginate: { itemsPerPage: 6, currentPage: shopLeftSidebar }">
                        <div class="single-products-box">
                            <div class="products-image">
                                <a class="d-block"
                                    [routerLink]="['/simple-product',Content.companyProductData.id,categoryId,categoryName]"
                                    [queryParams]="{productId: Content.companyProductData.id,categoryId,categoryName}">
                                    <img src="{{Content.photoString}}" alt="image">
                                </a>
                                <ul class="products-button">
                                    <li><a routerLink="/wishlist"><i class='bx bx-heart'></i></a></li>
                                    <li><a href="#" data-bs-toggle="modal" data-bs-target="#productsQuickView"><i
                                                class='bx bx-show-alt'></i></a></li>
                                    <li><a [routerLink]="['/simple-product',Content.companyProductData.id,categoryId,categoryName]"
                                            [queryParams]="{productId: Content.companyProductData.id,categoryId,categoryName}"
                                            class="popup-btn"><i class='bx bx-scan'></i></a></li>
                                    <li><a [routerLink]="['/simple-product',Content.companyProductData.id,categoryId,categoryName]"
                                            [queryParams]="{productId: Content.companyProductData.id,categoryId,categoryName}"><i
                                                class='bx bx-link'></i></a></li>
                                </ul>
                                <button style="border: 0px;"
                                    [queryParams]="{productId: Content.companyProductData.id,categoryId,categoryName}"
                                    [routerLink]="['/simple-product',Content.companyProductData.id,categoryId,categoryName]"
                                    class="add-to-cart-btn"><i class="bx bx-cart"></i>İncele</button>
                            </div>
                            <div class="products-content">
                                <h3><a [routerLink]="['/simple-product',Content.companyProductData.id]"
                                        [queryParams]="{productId: Content.companyProductData.id}">{{Content.companyProductData.title}}</a>
                                </h3>
                                <div class="price">
                                    <span class="new-price">{{Content.totalPrice}} TL</span>
                                    <span class="old-price" *ngIf="Content.oldPrice">{{Content.totalPrice}} TL</span>
                                </div>
                            </div>
                            <span class="on-sale" *ngIf="Content.sale">{{Content.sale}}</span>
                            <div class="out-of-stock" *ngIf="Content.outOfStock">{{Content.outOfStock}}</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-md-12">
                        <div class="pagination-area text-center">
                            <pagination-controls previousLabel="Geri" nextLabel="İleri"
                                (pageChange)="shopLeftSidebar = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Products Area -->