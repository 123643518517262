import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { apiConst } from 'src/app/constants/apiConst';
import { CartResponseModel } from 'src/app/models/cartmodel';
import { CompanyProductDetailApiResponse, ProductVariationDaum, ProductVariationShopData } from 'src/app/models/companyproductdetailModel';
import { CartService } from 'src/app/services/cartService';
import { ProductService } from 'src/app/services/product.service';
import { StorageService } from 'src/app/services/reactiveStorageService';
declare var $: any; // jQuery'yi kullanacağımızı belirtiyoruz

@Component({
    selector: 'app-simple-product-page',
    templateUrl: './simple-product-page.component.html',
    styleUrls: ['./simple-product-page.component.scss']
})
export class SimpleProductPageComponent implements OnInit {
    @ViewChild('carousel') carousel: ElementRef;
    @ViewChild('cartQuantity') cartQuantity: ElementRef;

    addCartButtonPressed: boolean = false;
    cartAdded: boolean = false;

    productOrder: number = 0;

    productId: string;
    categoryName: string;
    categoryId: string;

    companyProductSpecificationData: ProductVariationDaum
    productVariationDetails: Map<string, string>;

    companyProductDetailApiResponse: CompanyProductDetailApiResponse;
    productVariationShopData: ProductVariationShopData

    productDescription: string;
    selectedProductPhotoPath: string;
    productPhotoList: string[] = [];

    constructor(private router: Router, private localStorage: StorageService, private cartService: CartService, private activatedRoute: ActivatedRoute, private productService: ProductService) { }

    ngOnInit(): void {
        this.getRouterDatas();
        this.getProductDetailData()
    }

    productsDetailsImageSlidesOptions: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: false,
        lazyLoad: true,
        margin: 5,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>"
        ],
        responsive: {
            0: {
                items: 0,
            },
            576: {
                items: 0,
            },
            768: {
                items: 4,
            },
            1200: {
                items: 4,
            }
        }
    }

    getProductDetailData() {
        this.productService.ProductVariationShopGetByCompanyProductId(this.productId).subscribe({
            next: datas => {
                this.companyProductDetailApiResponse = datas.data;
                this.productVariationShopData = this.companyProductDetailApiResponse.productVariationShopData;
                this.productDescription = this.productVariationShopData.description;
                this.companyProductSpecificationData = this.productVariationShopData.productVariationData[this.productOrder];
                this.productVariationDetails = this.companyProductSpecificationData.productVariationDetails
                this.selectedProductPhotoPath = 'https://eirc.com.tr/assets/img/' + this.companyProductSpecificationData.productVariationPhotos[0]
                this.companyProductSpecificationData.productVariationPhotos.forEach((element) => {
                    this.productPhotoList.push('https://eirc.com.tr/assets/img/' + element);
                })
            }
        })
    }

    changeProductVariantProductOrder(productOrder: number) {
        this.productOrder = productOrder;
        this.getProductDetailData();
    }

    getRouterDatas() {
        this.activatedRoute.params.subscribe({
            next: parameter => {
                this.productId = parameter["productId"]
                this.categoryName = parameter["categoryName"]
                this.categoryId = parameter["categoryId"]
            }
        })
    }

    addCart() {
        this.addCartButtonPressed = true;
        let customerId: string = this.localStorage.get(apiConst.customerId);
        if (customerId == null) {
            this.router.navigate(['/login'])
            return;
        }
        let cartResponseModel: CartResponseModel = { customerId: customerId, customerName: this.localStorage.get(apiConst.customerName), companyProductSpecificationId: this.companyProductSpecificationData.companyProductSpecificationData.id, companyProductSpecificationName: this.companyProductSpecificationData.companyProductSpecificationData.name, quantity: this.cartQuantity.nativeElement.value, createdUserId: customerId }
        this.cartService.AddCart(cartResponseModel).subscribe({
            next: response => {
                if (response.isSuccess) {
                    this.cartAdded = true;
                    setTimeout(() => {
                        this.cartAdded = false;
                        this.addCartButtonPressed = false;
                    }, 1500);
                }
            }
        })
    }

    ChangeProductPhoto(selectedProductPhotoPath: string, photoIndex: number) {
        // Carousel'ı almak için ElementRef'i kullanıyoruz
        const carouselElement = this.carousel.nativeElement;
        // Örneğin, 2. slayda geçmek için:
        $(carouselElement).carousel(photoIndex);
        this.selectedProductPhotoPath = selectedProductPhotoPath;
    }


}