<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<!-- <div class="page-title-area"   style="background-image: url({{Content.bgImage}});">
    <h1>{{companyProductSpecificationData?.companyProductSpecificationData?.title}}</h1>
    <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{Content.title}}</li>
    </ul>
</div> -->
<!-- End Page Title Area -->

<!-- Start Products Details Area -->
<section class="products-details-area ptb-100 mt-5">
    <div class="container">
        <div class="row mt-3">
            <div class="col-lg-5 col-md-12">
                <div class="row">
                    <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);"
                        aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Ana Sayfa</a></li>
                            <li class="breadcrumb-item"><a
                                    [routerLink]="['/ürünler',categoryId,categoryName]">{{categoryName}}</a></li>
                            <li class="breadcrumb-item active" aria-current="page">{{companyProductSpecificationData?.companyProductSpecificationData?.productVariationName}}</li>
                        </ol>
                    </nav>
                </div>
                <div class="row">
                    <div class="products-details-image">
                        <div id="carouselExampleDark" class="carousel carousel-dark slide" #carousel>
                            <div class="carousel-inner">
                                <a href="{{selectedProductPhotoPath}}" class="popup-btn">
                                    <ng-container *ngFor="let item of productPhotoList;let i = index">
                                        <div class="carousel-item active photo{{i}}" *ngIf="i==0">
                                            <img src="{{productPhotoList[i]}}" class="d-block w-100 img-thumbnail">
                                        </div>
                                        <div class="carousel-item photo{{i}}" *ngIf="i!=0">
                                            <img src="{{productPhotoList[i]}}" class="d-block w-100 img-thumbnail">
                                        </div>
                                    </ng-container>
                                </a>
                            </div>
                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark"
                                data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark"
                                data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <section class="products-details-area ">
                        <div class="container">
                            <div class="products-details-image-slides">
                                <owl-carousel-o [options]="productsDetailsImageSlidesOptions">
                                    <ng-container *ngFor="let item of productPhotoList;let i = index">
                                        <ng-template carouselSlide>
                                            <div class="image" (click)="ChangeProductPhoto(productPhotoList[i],i)">
                                                <img src="{{productPhotoList[i]}}" class="img-thumbnail" alt="image">
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </owl-carousel-o>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="products-details-desc ">
                    <h3>{{companyProductSpecificationData?.companyProductSpecificationData?.productVariationName}}</h3>
                    <div class="price">
                        <span class="new-price">{{companyProductSpecificationData?.totalPrice}} TL</span>
                    </div>
                    <div class="row">
                        <div class="col-md-1">
                            <span><b style="font-size: 20px;">5.0</b></span>
                        </div>
                        <div class="col-md-3 p-0">
                            <div class="review-stars">
                                <i class='bx bxs-star checked align-middle'></i>
                                <i class='bx bxs-star checked align-middle'></i>
                                <i class='bx bxs-star checked align-middle'></i>
                                <i class='bx bxs-star checked align-middle'></i>
                                <i class='bx bxs-star checked align-middle'></i>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <b style="font-size: 15px;">6 Değerlendirme</b>
                    </div>
                    <div class="products-meta">
                        <div class="row">
                            <ng-container *ngFor="let item of productVariationShopData?.productProperties|keyvalue">
                                <div class="row mt-2">
                                    <b>{{item.key}}</b>
                                </div>
                                <div class="row">
                                    <ng-container *ngFor="let value of item.value;let i = index">
                                        <div class="col-md-2 mt-2">
                                            <ng-container *ngIf="i == productOrder">
                                                <div class="card" tabindex="0" style="border-color: dodgerblue;">
                                                    {{value}}</div>
                                            </ng-container>
                                            <ng-container *ngIf="i != productOrder">
                                                <div class="card" tabindex="0"
                                                    style="border-color: rgb(204, 204, 204);">
                                                    <a (click)="changeProductVariantProductOrder(i)">{{value}}</a>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="products-add-to-cart">
                        <div class="input-counter">
                            <span class="minus-btn"><i class='bx bx-minus'></i></span>
                            <input type="text" min="0" max="10" value="1" #cartQuantity>
                            <span class="plus-btn"><i class='bx bx-plus'></i></span>
                        </div>
                        <button type="submit" class="default-btn" style="background-color:#198754 ;" (click)="addCart()"
                            *ngIf="cartAdded"><i class='bx bxs-cart-add'></i>Sepete Eklendi</button>
                        <button type="submit" class="default-btn" (click)="addCart()" *ngIf="!addCartButtonPressed"><i
                                class='bx bxs-cart-add'></i>Sepete Ekle</button>
                        <button class="default-btn" type="button" disabled *ngIf="addCartButtonPressed && !cartAdded">
                            <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                            <span role="status">Ekleniyor...</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="products-details-tabs">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="description-tab" data-bs-toggle="tab"
                                data-bs-target="#description" type="button" role="tab" aria-controls="description"
                                aria-selected="false">Açıklama</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="additional-information-tab" data-bs-toggle="tab"
                                data-bs-target="#additional-information" type="button" role="tab"
                                aria-controls="additional-information" aria-selected="false">Ek Bilgi</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews"
                                type="button" role="tab" aria-controls="reviews" aria-selected="false">Yorumlar</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="description" role="tabpanel">
                            <p [innerHTML]="productDescription"></p>
                        </div>
                        <div class="tab-pane fade " id="additional-information" role="tabpanel">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>Satıcı</td>
                                            <td>{{this.companyProductSpecificationData?.companyProductSpecificationData.companyName}}
                                            </td>
                                        </tr>
                                        <ng-container
                                            *ngFor="let item of productVariationShopData?.productProperties |keyvalue">
                                            <tr>
                                                <td>{{item.key}}</td>
                                                <td>{{item.value[productOrder]}}</td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="reviews" role="tabpanel">
                            <div class="products-review-comments">
                                <div class="user-review">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                        </div>
                                        <span class="d-inline-block">Aylin Yılmaz</span>
                                    </div>
                                    <p>Bu spor sandalet, yaz aylarında rahatlıkla kullanabileceğiniz bir seçenek. Nefes
                                        alabilir malzemesi, sıcak havalarda terlemeyi minimumda tutuyor ve ayaklarınızı
                                        serin tutuyor.</p>
                                </div>

                                <div class="user-review">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                        </div>
                                        <span class="d-inline-block">Elif Demir</span>
                                    </div>
                                    <p>Bu yürüyüş ayakkabısı, uzun yürüyüşler için ideal. Ayak tabanını mükemmel şekilde
                                        destekliyor ve yastıklama sistemi sayesinde her adımda konfor sağlıyor.</p>
                                </div>
                                <div class="user-review">
                                    <div class="review-rating">
                                        <div class="review-stars">
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star checked'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>
                                        <span class="d-inline-block">Canan Aksoy</span>
                                    </div>
                                    <p>Bu koşu ayakkabısı, koşu tutkunları için mükemmel bir seçenek. Hafif yapısıyla
                                        hızlı koşuları desteklerken, aynı zamanda ayak bileğini ve tabanı mükemmel
                                        şekilde destekliyor.</p>
                                </div>
                            </div>
                            <div class="review-form-wrapper">
                                <h3>Yorum Yap</h3>
                                <p class="comment-notes">E-posta adresiniz yayınlanmayacaktır. Zorunlu alanlar
                                    işaretlenir <span>*</span></p>
                                <form>
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <div class="rating">
                                                <input type="radio" id="star5" name="rating" value="5" /><label
                                                    for="star5"></label>
                                                <input type="radio" id="star4" name="rating" value="4" /><label
                                                    for="star4"></label>
                                                <input type="radio" id="star3" name="rating" value="3" /><label
                                                    for="star3"></label>
                                                <input type="radio" id="star2" name="rating" value="2" /><label
                                                    for="star2"></label>
                                                <input type="radio" id="star1" name="rating" value="1" /><label
                                                    for="star1"></label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Ad Soyad *">
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <input type="email" class="form-control" placeholder="Email *">
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <textarea placeholder="Yorumunuz.." class="form-control" cols="30"
                                                    rows="6"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <p class="comment-form-cookies-consent">
                                                <input type="checkbox" id="test1">
                                                <label for="test1">Adımı, e-postamı ve web sitemi bu tarayıcıya kaydet
                                                    bir dahaki yorumumda.</label>
                                            </p>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <button type="submit">Gönder</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Products Details Area -->