<div class="login-form">
    <h2 class="text-center">Şifre Güncelle</h2>
    <form [formGroup]="registerForm" (ngSubmit)="passwordUpdate()">
        <div class="form-group">
            <label>Şifre</label>
            <input formControlName="password" type="password" class="form-control" placeholder="Şifre"
                [ngClass]="{'error': registerForm.get('password').invalid && (registerForm.get('password').dirty || registerForm.get('password').touched)}">
            <div class="mt-3 text-danger"
                *ngIf="registerForm.get('password').hasError('required') && (registerForm.get('password').dirty || registerForm.get('password').touched)">
                Şifre alanı boş bırakılamaz.
            </div>
        </div>
        <div class="form-group">
            <label>Şifre Tekrar</label>
            <input formControlName="confirmPassword" type="password" class="form-control" placeholder="Şifre Tekrar"
                [ngClass]="{'error': registerForm.get('confirmPassword').invalid && (registerForm.get('confirmPassword').dirty || registerForm.get('confirmPassword').touched)}">
            <div class="mt-3 text-danger"
                *ngIf="registerForm.get('confirmPassword').hasError('required') && (registerForm.get('confirmPassword').dirty || registerForm.get('confirmPassword').touched)">
                Şifre tekrar alanı boş bırakılamaz.
            </div>
            <div class="mt-3 text-danger"
                *ngIf="registerForm.hasError('passwordMismatch') && (registerForm.get('confirmPassword').dirty || registerForm.get('confirmPassword').touched)">
                Şifreler uyuşmuyor.
            </div>
        </div>
        <p class="description">Parola en az sekiz karakter uzunluğunda olmalıdır. Daha
            güçlü hale getirmek için büyük ve küçük harfler, sayılar ve semboller
            kullanın! " ? $ % ^ & )</p>

        <button type="submit" disabled>Güncelle</button>
    </form>
</div>