<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1>{{Content.title}}</h1>
    <ul>
        <li><a routerLink="/">Anasayfa</a></li>
        <li>{{Content.title}}</li>
    </ul>
</div>
<!-- End Page Title Area -->

<!-- Start Cart Area -->
<section class="cart-area ptb-100">
    <div class="container">
        <form>
            <div class="cart-table table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Ürün</th>
                            <th scope="col">Ürün Adı</th>
                            <th scope="col">Fiyat</th>
                            <th scope="col">Miktar</th>
                            <th scope="col">Toplam</th>
                        </tr>
                    </thead>
                    <tbody>

                        <ng-container *ngFor="let item of cartModelList;let i = index;">
                            <tr>
                                <td class="product-thumbnail">
                                    <a><img src="{{item.photoString}}" alt="item"></a>
                                </td>
                                <td class="product-name">
                                    <a>{{item.companyProductSpecificationData.title}}</a>
                                </td>

                                <td class="product-price">
                                    <span class="unit-amount">{{item.productPrice}} TL</span>
                                </td>
                                <td class="product-quantity">
                                    <div class="input-counter">
                                        <span class="minus-btn"
                                            (click)="updateProductQuantity(i,(item.cartData.quantity - 1 ),item.cartData.id)"><i
                                                class='bx bx-minus'></i></span>
                                        <input type="text" min="1" value="{{item.cartData.quantity}}">
                                        <span class="plus-btn"
                                            (click)="updateProductQuantity(i,(item.cartData.quantity + 1),item.cartData.id)"><i
                                                class='bx bx-plus'></i></span>
                                    </div>
                                </td>
                                <td class="product-subtotal">
                                    <span class="subtotal-amount">{{item.productTotalPrice}} TL</span>
                                    <button class="remove"
                                        (click)="deleteProduct($event,i,item.cartData.id,item.cartData.companyProductSpecificationName)"
                                        style="border: 0;background-color: white;"><i class='bx bx-trash'></i></button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <div class="cart-buttons">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-sm-12 col-md-7">
                        <div class="shopping-coupon-code">
                            <input type="text" class="form-control" placeholder="Kupon Kodu" name="coupon-code"
                                id="coupon-code">
                            <button type="submit">Kuponu Uygula</button>
                        </div>
                    </div>

                    <!-- <div class="col-lg-5 col-sm-12 col-md-5 text-right">
                        <a routerLink="/shop-left-sidebar-1" class="default-btn"><i class='bx bx-refresh'></i> Sepete
                            Ürün Ekle</a>
                    </div> -->
                </div>
            </div>

            <div class="cart-totals">
                <ul>
                    <li>Sepet <span>{{cartTotalModel?.totalPrice}} TL</span></li>
                    <li>Kargo <span>0 TL</span></li>
                    <li>Toplam <span>{{cartTotalModel?.totalPrice}} TL</span></li>
                </ul>
                <a class="default-btn px-4" routerLink="/siparis-tamamla">Sepeti Onayla</a>
            </div>
        </form>

    </div>
</section>
<!-- End Cart Area -->