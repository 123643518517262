<app-navbar-style-one></app-navbar-style-one>

<!-- Start Profile Authentication Area -->
<section class="profile-authentication-area ptb-100">
    <div class="container">
        <div class="row d-flex justify-content-center mt-5">
            <div class="col-lg-6 col-md-12">
                <div class="login-form">
                    <h2>Kayıt Ol</h2>
                    <form [formGroup]="registerForm" (ngSubmit)="register()">
                        <div class="form-group">
                            <label>Ad</label>
                            <input formControlName="firstName" type="text" class="form-control" placeholder="Ad"
                                [ngClass]="{'error': registerForm.get('firstName').invalid && (registerForm.get('firstName').dirty || registerForm.get('firstName').touched)}">
                            <div class="mt-3 text-danger"
                                *ngIf="registerForm.get('firstName').hasError('required') && (registerForm.get('firstName').dirty || registerForm.get('firstName').touched)">
                                Ad alanı boş bırakılamaz.
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Soyad</label>
                            <input formControlName="lastName" type="text" class="form-control" placeholder="Soyad"
                                [ngClass]="{'error': registerForm.get('lastName').invalid && (registerForm.get('lastName').dirty || registerForm.get('lastName').touched)}">
                            <div class="mt-3 text-danger"
                                *ngIf="registerForm.get('lastName').hasError('required') && (registerForm.get('lastName').dirty || registerForm.get('lastName').touched)">
                                Soyad alanı boş bırakılamaz.
                            </div>
                        </div>
                        <div class="form-group">
                            <label>E-mail</label>
                            <input formControlName="mailAddress" type="email" class="form-control" placeholder="E-mail"
                                [ngClass]="{'error': registerForm.get('mailAddress').invalid && (registerForm.get('mailAddress').dirty || registerForm.get('mailAddress').touched)}">
                            <div class="mt-3 text-danger"
                                *ngIf="registerForm.get('mailAddress').hasError('required') && (registerForm.get('mailAddress').dirty || registerForm.get('mailAddress').touched)">
                                E-mail alanı boş bırakılamaz.
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Telefon</label>
                            <input formControlName="phoneNumber" type="text" class="form-control" placeholder="Telefon"
                                [ngClass]="{'error': registerForm.get('phoneNumber').invalid && (registerForm.get('phoneNumber').dirty || registerForm.get('phoneNumber').touched)}">
                            <div class="mt-3 text-danger"
                                *ngIf="registerForm.get('phoneNumber').hasError('required') && (registerForm.get('phoneNumber').dirty || registerForm.get('phoneNumber').touched)">
                                Telefon alanı boş bırakılamaz.
                            </div>
                        </div>
                        <!-- Diğer form alanları buraya eklenebilir -->
                        <div class="form-group">
                            <label>Şifre</label>
                            <input formControlName="password" type="password" class="form-control" placeholder="Şifre"
                                [ngClass]="{'error': registerForm.get('password').invalid && (registerForm.get('password').dirty || registerForm.get('password').touched)}">
                            <div class="mt-3 text-danger"
                                *ngIf="registerForm.get('password').hasError('required') && (registerForm.get('password').dirty || registerForm.get('password').touched)">
                                Şifre alanı boş bırakılamaz.
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Şifre Tekrar</label>
                            <input formControlName="confirmPassword" type="password" class="form-control"
                                placeholder="Şifre Tekrar"
                                [ngClass]="{'error': registerForm.get('confirmPassword').invalid && (registerForm.get('confirmPassword').dirty || registerForm.get('confirmPassword').touched)}">
                            <div class="mt-3 text-danger"
                                *ngIf="registerForm.get('confirmPassword').hasError('required') && (registerForm.get('confirmPassword').dirty || registerForm.get('confirmPassword').touched)">
                                Şifre tekrar alanı boş bırakılamaz.
                            </div>
                            <div class="mt-3 text-danger"
                                *ngIf="registerForm.hasError('passwordMismatch') && (registerForm.get('confirmPassword').dirty || registerForm.get('confirmPassword').touched)">
                                Şifreler uyuşmuyor.
                            </div>
                        </div>

                        <p class="description">Parola en az sekiz karakter uzunluğunda olmalıdır. Daha güçlü hale
                            getirmek için büyük ve küçük harfler, sayılar ve semboller kullanın! " ? $ % ^ & )</p>

                        <button type="submit">Kayıt Ol</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Start Profile Authentication Area -->