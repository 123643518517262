import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-not-found-page',
    templateUrl: './not-found-page.component.html',
    styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }
    
    errorContent = [
        {
            img: 'assets/img/error.png',
            title: 'Hata 404 : Sayfa Bulunamadı',
            paragraph: 'Aradığınız sayfanın adı değiştirilmiş veya geçici olarak  kaldırılmış olabilir.'
        }
    ]

}