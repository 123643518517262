export enum optionMapConst {
    contactInfo = 1,
    mail = 2,
    address = 3,
    aboutUs = 4,
    logo = 5,
    facebook = 6,
    companyName = 7,
    slider = 8,
    products = 9,
    homePageBanner = 10,
    referance = 11,
    privacyPolicy = 12,
    returnPolicy = 13,
    termsandconditions = 14,
    sss = 15,
    accountInfo = 16,
    orderDone = 17
}