<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1>{{Content.title}}</h1>
    <ul>
        <li><a routerLink="/">Ana Sayfa</a></li>
        <li>{{Content.title}}</li>
    </ul>
</div>
<!-- End Page Title Area -->

<!-- Start Checkout Area -->
<section class="checkout-area ptb-100">
    <div class="container">
        <form [formGroup]="orderFormGroup" (ngSubmit)="addOrder()">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="billing-details">
                        <h3 class="title">Sipariş Detayı</h3>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Ad & Soyad<span class="required">*</span></label>
                                    <input type="text" class="form-control" formControlName="name">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Telefon <span class="required">*</span></label>
                                    <input type="tel" class="form-control" formControlName="phone">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="notes" id="notes" cols="30" rows="5" placeholder="Adres"
                                        class="form-control" formControlName="address"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="order-details">
                        <h3 class="title">Sepetiniz</h3>
                        <div class="order-table table-responsive">
                            <table class="table table-bordered">
                                <tbody>
                                    <tr *ngFor="let item of cartModelList">
                                        <td class="product-name"><a
                                                routerLink="/simple-product">{{item.cartData.companyProductSpecificationName}}</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">{{item.productTotalPrice}} TL</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="payment-box">
                            <div class="payment-method">
                                <p>
                                    <input type="radio" id="direct-bank-transfer" name="radio-group" checked>
                                    <label for="direct-bank-transfer">Online Ödeme</label>
                                    Dilediğiniz bankadan online ödeme yapabilirsiniz
                                </p>
                                <p>
                                    <input type="radio" id="cash-on-delivery" name="radio-group">
                                    <label for="cash-on-delivery">Kapıda Ödeme</label>
                                </p>
                            </div>
                            <button type="submit" class="default-btn"><i class='bx bx-paper-plane'></i> Siparişi
                                Tamamla</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>
<!-- End Checkout Area -->