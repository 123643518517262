import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { apiConst } from 'src/app/constants/apiConst';
import { optionMapConst } from 'src/app/constants/optionMapConst';
import { CustomerModel, CustomerData } from 'src/app/models/customermodel';
import { SiteInformationApiModel } from 'src/app/models/siteInformationModel';
import { CustomerService } from 'src/app/services/customer.service';
import { StorageService } from 'src/app/services/reactiveStorageService';
import { SiteInformationService } from 'src/app/services/siteInformation';

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss']
})
export class CustomerInfoComponent implements OnInit {
  //çerezlere eklenen customerId ile ilerlenicek
  public registerForm: FormGroup;
  customerData: CustomerModel;
  customerFormApi: CustomerData;


  constructor(
    private _fb: FormBuilder,
    private customerService: CustomerService,
    private toastrService: ToastrService,
    private router: Router,
    private ractiveLocalStorage: StorageService,
    private siteInformationService: SiteInformationService
  ) { }

  ngOnInit(): void {
    this.registerForm = this._fb.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      customerName: ["", Validators.required],
      password: ["", [Validators.required]],
      confirmPassword: ['', Validators.required],
      mailAddress: ["", [Validators.required]],
      phoneNumber: ["", [Validators.required]]
    }, {
      validator: this.passwordMatchValidator
    })

    this.getCustomerDatas();
   
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password').value;
    const confirmPassword = formGroup.get('confirmPassword').value;

    if (password === confirmPassword) {
      return null; // Şifreler eşleşiyorsa hata yok
    } else {
      return { passwordMismatch: true }; // Hata nesnesi döndür
    }
  }

  register() {
    console.log("pressed button to register")
  }

  getCustomerDatas() {
    this.customerService.CustomerDetail(this.ractiveLocalStorage.get(apiConst.customerId)).subscribe({
      next: response => {
        this.customerData = response.data
        this.customerFormApi = this.customerData.customerData;
        this.registerForm.setValue({ firstName: this.customerFormApi.firstName, lastName: this.customerFormApi.lastName, customerName: this.customerFormApi.customerName, password: null, confirmPassword: null, mailAddress: this.customerFormApi.mailAddress, phoneNumber: this.customerFormApi.phoneNumber })
      }
    })
  }


}
