<!-- <app-navbar-style-two></app-navbar-style-two> -->

<!-- Start 404 Error Area -->
<section class="error-area ptb-100">
    <div class="container">
        <div class="error-content">
            <img src={{siteInformation[0].siteInformationPhotos[0].photoString}} alt="image">
            <h3>{{siteInformation[0].siteInformationPhotos[0].title}}</h3>
            <div [innerHTML]="siteInformation[0].siteInformationPhotos[0].text"></div>
            <a routerLink={{siteInformation[0].siteInformationPhotos[0].link}} class="default-btn"><i class='bx bx-home-circle'></i> Anasayfaya Dön</a>
        </div>
    </div>
</section>
<!-- End 404 Error Area -->