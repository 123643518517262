import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BrandModel, BrandModelApiResponse } from 'src/app/models/brandModel';
import { BrandService } from 'src/app/services/brand.service';

@Component({
  selector: 'app-brands-slider',
  templateUrl: './brands-slider.component.html',
  styleUrls: ['./brands-slider.component.scss']
})
export class BrandsSliderComponent implements OnInit {

  brandModelApiResponse: BrandModelApiResponse;
  brandsData: BrandModel[]

  constructor(private brandService: BrandService) { }

  ngOnInit(): void {
    this.getBrands()
  }


  getBrands() {
    this.brandService.GetAllBrandList().subscribe({
      next: datas => {
        this.brandModelApiResponse = datas.data;
        this.brandsData = this.brandModelApiResponse.brandsData;
      }
    })
  }












  partnerItem = [
    {
      link: '#',
      img: 'assets/img/partner/partner1.png'
    },
    {
      link: '#',
      img: 'assets/img/partner/partner2.png'
    },
    {
      link: '#',
      img: 'assets/img/partner/partner3.png'
    },
    {
      link: '#',
      img: 'assets/img/partner/partner4.png'
    },
    {
      link: '#',
      img: 'assets/img/partner/partner5.png'
    }
    ,
    {
      link: '#',
      img: 'assets/img/partner/partner5.png'
    },
    {
      link: '#',
      img: 'assets/img/partner/partner5.png'
    },
    {
      link: '#',
      img: 'assets/img/partner/partner5.png'
    },
    {
      link: '#',
      img: 'assets/img/partner/partner5.png'
    },
    {
      link: '#',
      img: 'assets/img/partner/partner5.png'
    },
    {
      link: '#',
      img: 'assets/img/partner/partner5.png'
    },
    {
      link: '#',
      img: 'assets/img/partner/partner5.png'
    }
  ]

  partnerSlidesOptions: OwlOptions = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 10,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 2
      },
      576: {
        items: 3
      },
      768: {
        items: 4
      },
      992: {
        items: 8
      }
    }
  }

}
