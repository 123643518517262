import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { apiConst } from 'src/app/constants/apiConst';
import { optionMapConst } from 'src/app/constants/optionMapConst';
import { SiteInformation, SiteInformationApiModel, SiteInformationRequestModel } from 'src/app/models/siteInformationModel';
import { SiteInformationService } from 'src/app/services/siteInformation';

@Component({
    selector: 'app-partner',
    templateUrl: './partner.component.html',
    styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

    siteInformationApiModel: SiteInformationApiModel
    siteInformationList: SiteInformation[] = [];

    constructor(private siteInformationService: SiteInformationService) { }

    ngOnInit(): void {
        let siteInformationRequestModel: SiteInformationRequestModel = { type: [optionMapConst.referance], userId: apiConst.userId }
        this.siteInformationService.GetSiteInformationByType(siteInformationRequestModel).subscribe({
            next: datas => {
                this.siteInformationApiModel = datas.data;
                this.siteInformationList = this.siteInformationApiModel.siteInformationsData
                this.siteInformationList[0].siteInformationPhotos[0].photoString = "https://eirc.com.tr/assets/img/"+     this.siteInformationList[0].siteInformationPhotos[0].photoString
            }
        })
    }
    partnerItem = [
        {
            link: '#',
            img: 'assets/img/partner/partner1.png'
        },
        {
            link: '#',
            img: 'assets/img/partner/partner2.png'
        },
        {
            link: '#',
            img: 'assets/img/partner/partner3.png'
        },
        {
            link: '#',
            img: 'assets/img/partner/partner4.png'
        },
        {
            link: '#',
            img: 'assets/img/partner/partner5.png'
        }
    ]

    partnerSlidesOptions: OwlOptions = {
		loop: true,
		nav: false,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 2
			},
			576: {
				items: 3
			},
			768: {
				items: 4
			},
			992: {
				items: 5
			}
		}
    }

}