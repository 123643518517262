import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { apiConst } from 'src/app/constants/apiConst';
import { optionMapConst } from 'src/app/constants/optionMapConst';
import { CategoryModel, CategoryModelApiResponse } from 'src/app/models/categoryModel';
import { CompanyProductModel, ProductShopGetByCategoryIdRequestModel, ProductShopResponseModel } from 'src/app/models/productModel';
import { SiteInformation, SiteInformationApiModel } from 'src/app/models/siteInformationModel';
import { CategoryService } from 'src/app/services/category.service';
import { ProductService } from 'src/app/services/product.service';
import { SiteInformationService } from 'src/app/services/siteInformation';
import { CategoryPropertiesModel, CategoryPropertiesModelApiResponse } from 'src/app/models/categoryPropertiesModel';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

    productShopResponseModel: ProductShopResponseModel;
    companyProductData: CompanyProductModel[]
    searchTerm: string = '';

    siteInformationApiModel: SiteInformationApiModel
    siteInformationList: SiteInformation[] = [];

    categoryModelApiResponse: CategoryModelApiResponse;
    categoriesData: CategoryModel[];


    subcategoryModelApiResponse: CategoryModelApiResponse;
    subcategoriesData: CategoryModel[];

    categoryPropertiesModelApiResponse: CategoryPropertiesModelApiResponse;
    categoriesPropertiesData: CategoryPropertiesModel[];

    categoryId: string;
    categoryName: string;

    constructor(private activatedRoute: ActivatedRoute, private categoryService: CategoryService, private spinner: NgxSpinnerService, private productService: ProductService, private siteInformationService: SiteInformationService) { }

    ngOnInit(): void {
        this.getRouterDatas();
        this.spinner.show();
        this.resetOption = [this.options[0]];
        this.getPageInfo();
    }

    getProductsByCategoryId(categoryId: string) {
        let productShopGetByCategoryIdRequestModel: ProductShopGetByCategoryIdRequestModel = { categoryIds: [categoryId], orderByPrice: true, userId: apiConst.userId }
        this.productService.ProductShopGetByCategoryId(productShopGetByCategoryIdRequestModel).subscribe({
            next: datas => {
                this.productShopResponseModel = datas.data;
                this.companyProductData = this.productShopResponseModel.productShopsData;
                this.companyProductData.forEach((element) => {
                    element.photoString = "https://eirc.com.tr/assets/img/" + element.photoString
                })
                this.spinner.hide();
            }
        })
    }

    getSubCategories() {
        this.categoryService.GetSubCategory(this.categoryId).subscribe({
            next: datas => {
                this.subcategoryModelApiResponse = datas.data;
                this.subcategoriesData = this.subcategoryModelApiResponse.categoriesData;
            }
        })
    }

    getCategoryPropertiesList() {
        this.categoryService.GetAllCategoryPropertyList(this.categoryId).subscribe({
            next: datas => {
                this.categoryPropertiesModelApiResponse = datas.data;
                this.categoriesPropertiesData = this.categoryPropertiesModelApiResponse.categoryPropertiesData
            }
        })
    }

    getPageInfo() {
        this.siteInformationService.GetSiteInformationByType({ type: [optionMapConst.products], userId: apiConst.userId }).subscribe({
            next: datas => {
                this.siteInformationApiModel = datas.data;
                this.siteInformationList = this.siteInformationApiModel.siteInformationsData
                this.siteInformationList[0].siteInformationPhotos[0].photoString = "https://eirc.com.tr/assets/img/" + this.siteInformationList[0].siteInformationPhotos[0].photoString
            }
        })
    }

    getRouterDatas() {
        this.activatedRoute.params.subscribe({
            next: parameter => {
                this.categoryId = parameter["categoryId"]
                this.categoryName = parameter["categoryName"]
                this.getProductsByCategoryId(this.categoryId);
                this.getSubCategories();
                this.getCategoryPropertiesList();
            }
        })
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Ürünler'
        }
    ]


    // For Pagination
    shopLeftSidebar: number = 1;

    // Category Select
    singleSelect: any = [];
    multiSelect: any = [];
    stringArray: any = [];
    objectsArray: any = [];
    resetOption: any;
    config = {
        displayKey: "name",
        search: true,
        placeholder:'Önerilen Sıralama',
        searchPlaceholder:'Ara...'
    };
    options = [
        {
            name: "Varsayılan",
        },
        {
            name: "Popülarite",
        },
        {
            name: "En Son",
        },
        {
            name: "En düşük Fiyat",
        },
        {
            name: "En Yüksek Fiyat",
        }
    ];
    searchChange($event) {
        console.log($event);
    }
    reset() {
        this.resetOption = [];
    }

}
