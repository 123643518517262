<app-navbar-style-one></app-navbar-style-one>

<!-- Start Profile Authentication Area -->
<section class="profile-authentication-area ptb-100">
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-md-6 mt-5">
                <div class="login-form">
                    <h2>Giriş</h2>

                    <form [formGroup]="loginForm" (ngSubmit)="login()">
                        <div class="form-group">
                            <label>Kullanıcı adı ve şifre</label>
                            <input formControlName="mailAddress" type="text" class="form-control" placeholder="kullanıcı adı veya şifre">
                        </div>

                        <div class="form-group">
                            <label>Şifre</label>
                            <input formControlName="password" type="password" class="form-control" placeholder="şifre">
                        </div>

                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                                <p>
                                    <input type="checkbox" id="test2">
                                    <label for="test2">Beni Hatırla</label>
                                </p>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                                <a routerLink="/sifremi-unuttum" class="lost-your-password">Şifremi Unuttum?</a>
                            </div>
                        </div>

                        <button type="submit">Giriş Yap</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Start Profile Authentication Area -->