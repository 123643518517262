import { Component, OnInit } from '@angular/core';
import { apiConst } from 'src/app/constants/apiConst';
import { CategoryModel, CategoryModelApiResponse } from 'src/app/models/categoryModel';
import { ContactInfoApiModel, ContactInfoModel } from 'src/app/models/siteInformationModel';
import { CategoryService } from 'src/app/services/category.service';
import { StorageService } from 'src/app/services/reactiveStorageService';
import { SiteInformationService } from 'src/app/services/siteInformation';

@Component({
    selector: 'app-navbar-style-one',
    templateUrl: './navbar-style-one.component.html',
    styleUrls: ['./navbar-style-one.component.scss']
})
export class NavbarStyleOneComponent implements OnInit {

    categoryModelApiResponse: CategoryModelApiResponse;
    categoriesData: CategoryModel[]

    contactInfoApiModel: ContactInfoApiModel;
    contactInfoData: ContactInfoModel

    isLogin: boolean = false;

    constructor(private localStorage: StorageService, private webSiteInfoService: SiteInformationService, private categoryService: CategoryService) { }

    ngOnInit(): void {
        if (this.localStorage.get(apiConst.customerId) != null) {
            this.isLogin = true;
        }else {
            this.isLogin = false;
        }
        this.getContactInfo()
        this.getCategories();
    }

    getContactInfo() {
        this.webSiteInfoService.GetContactInfo(apiConst.userId).subscribe({
            next: datas => {
                this.contactInfoApiModel = datas.data;
                this.contactInfoData = this.contactInfoApiModel.contactInfoData
            }
        })
    }

    getCategories() {
        this.categoryService.GetAllCategoryList().subscribe({
            next: datas => {
                this.categoryModelApiResponse = datas.data;
                this.categoriesData = this.categoryModelApiResponse.categoriesData
            }
        })
    }

    logOut(){
        this.localStorage.remove(apiConst.customerId)
        this.localStorage.remove(apiConst.customerName)
    }
}