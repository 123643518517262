import { Component, OnInit } from '@angular/core';
import { apiConst } from 'src/app/constants/apiConst';
import { optionMapConst } from 'src/app/constants/optionMapConst';
import { CompanyProductModel, ProductShopResponseModel } from 'src/app/models/productModel';
import { SiteInformation, SiteInformationApiModel, SiteInformationRequestModel } from 'src/app/models/siteInformationModel';
import { SiteInformationService } from 'src/app/services/siteInformation';

@Component({
    selector: 'app-facility-style-one',
    templateUrl: './facility-style-one.component.html',
    styleUrls: ['./facility-style-one.component.scss']
})
export class FacilityStyleOneComponent implements OnInit {

    siteInformationApiModel: SiteInformationApiModel
    siteInformationList: SiteInformation[] = [];

    constructor(private siteInformationService: SiteInformationService) { }

    ngOnInit(): void {
        let siteInformationRequestModel: SiteInformationRequestModel = { type: [optionMapConst.homePageBanner], userId: apiConst.userId }
        this.siteInformationService.GetSiteInformationByType(siteInformationRequestModel).subscribe({
            next: datas => {
                this.siteInformationApiModel = datas.data;
                this.siteInformationList = this.siteInformationApiModel.siteInformationsData
                this.siteInformationList[0].siteInformationPhotos[0].photoString = "https://eirc.com.tr/assets/img/" + this.siteInformationList[0].siteInformationPhotos[0].photoString
            }
        })
    }
    facilityContent = [
        {
            icon: 'bx bx-shopping-bag',
            title: 'Online alışveriş',
            paragraph: 'Hemen Tıkla, Kolayca Al! Online alışverişin keyfini çıkarın ve binlerce ürün arasından istediğiniz ürünleri seçerek kapınıza getirelim.'
        },
        {
            icon: 'bx bxs-plane-take-off',
            title: 'Ücretsiz Kargo',
            paragraph: 'Alışveriş Yapmanın Keyfi: Tüm alışverişlerinizde ücretsiz kargo imkanı! Siz siparişinizi verin, gerisini biz halledelim.'
        },
        {
            icon: 'bx bx-info-square',
            title: 'İade Politikası',
            paragraph: 'Memnuniyetsizlik Yok! Ürünlerimizden memnun kalmamanız durumunda, kolay iade politikamız ile sorunsuz bir şekilde ürün iade ve değişim işlemlerinizi gerçekleştirebilirsiniz.'
        },
        {
            icon: 'bx bx-check-shield',
            title: 'Güvenli Ödemeler',
            paragraph: 'Güvenli Alışverişin Adı: Ödeme işlemleriniz endişe verici olmasın! Güvenli ödeme seçenekleriyle, kişisel bilgileriniz koruma altında.'
        }
    ]
    facilityImage = [
        {
            img: 'assets/img/facility-img.png'
        }
    ]

}