<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{bannerPhotoString}});">
    <h1>{{Content.title}}</h1>
    <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{Content.title}}</li>
    </ul>
</div>
<!-- End Page Title Area -->
<!-- Start Products Area -->
<section class="products-area ptb-100" *ngIf="userLogin">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area left-widget-area">
                    <div class="widget widget_categories">
                        <h3 class="widget-title text-center">Hesap Bilgilerim</h3>
                        <ul class="text-center">
                            <li><a href="#" (click)="changeSelectedPage($event,0)">Üyelik Bilgileri</a>
                            </li>
                            <li><a href="#" (click)="changeSelectedPage($event,1)">Şifre Güncelle</a>
                            </li>
                            <li><a href="#" (click)="changeSelectedPage($event,2)">Siparişlerim</a>
                            </li>
                            <li><a href="#" (click)="changeSelectedPage($event,3)">Adreslerim</a>
                            </li>
                            <li><a href="#" (click)="changeSelectedPage($event,4)">Destek Kayıtları</a>
                            </li>
                            <li><a href="/" (click)="logOut()" class="nav-link">Çıkış
                                    Yap</a>
                            </li>
                        </ul>
                    </div>
                </aside>
            </div>
            <div class="col-lg-8 col-md-12">
                <app-customer-info *ngIf="selectedPageNumber == 0"></app-customer-info>
                <app-password-update *ngIf="selectedPageNumber == 1"></app-password-update>
                <app-order-list *ngIf="selectedPageNumber == 2"></app-order-list>
                <app-address-common *ngIf="selectedPageNumber == 3"></app-address-common>
            </div>
        </div>
    </div>
</section>
<!-- End Products Area -->