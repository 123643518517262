import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { apiConst } from 'src/app/constants/apiConst';
import { CartModel, CartApiModel } from 'src/app/models/cartmodel';
import { AddOrderModel, OrderDetail } from 'src/app/models/orderModel';
import { AlertService } from 'src/app/services/alertService';
import { CartService } from 'src/app/services/cartService';
import { OrderService } from 'src/app/services/orderService';
import { StorageService } from 'src/app/services/reactiveStorageService';

@Component({
  selector: 'app-add-order',
  templateUrl: './add-order.component.html',
  styleUrls: ['./add-order.component.scss']
})
export class AddOrderComponent implements OnInit {

  pageTitle = [
    {
      bgImage: 'assets/img/page-title-bg.jpg',
      title: 'Sipariş Tamamlama'
    }
  ]

  orderFormGroup: FormGroup;

  cartModelList: CartModel[];
  cartApiModel: CartApiModel;

  addOrderModel: AddOrderModel;

  customerId: string;
  customerName: string;

  basketPrice: number = 0;

  constructor(private router : Router,private alertService: AlertService, private orderService: OrderService, private formBuilder: FormBuilder, private cartService: CartService, private localStorage: StorageService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.customerId = this.localStorage.get(apiConst.customerId)
    this.customerName = this.localStorage.get(apiConst.customerName)
    this.spinner.show();
    this.orderFormBuilder();
    this.getCustomerCart();
  }

  getCustomerCart() {
    this.cartService.CartGetByCustomerId(this.customerId).subscribe({
      next: datas => {
        this.spinner.hide();
        this.cartApiModel = datas.data;
        this.cartModelList = this.cartApiModel.cartsData
        this.cartModelList.forEach((element) => {
          this.basketPrice += element.productTotalPrice
        })
      }
    })
  }

  orderFormBuilder() {
    this.orderFormGroup = this.formBuilder.group({
      name: ["", [Validators.required]],
      phone: ["", [Validators.required]],
      address: ["", [Validators.required]]
    })
  }

  addOrder() {
    let orderFormGroupValues = this.orderFormGroup.value;

    this.addOrderModel = {
      customerId: this.customerId,
      customerName: this.customerName,
      basketPrice: this.basketPrice,
      courierFee: 0,
      createdUserId: this.customerId,
      customerAddres: orderFormGroupValues.address,
      customerPhoneNumber: orderFormGroupValues.phone,
      totalPrice: this.basketPrice,
      orderDetails: []
    }

    this.cartModelList.forEach((element) => {
      this.addOrderModel.orderDetails.push({
        companyId: element.companyProductSpecificationData.companyId,
        companyName: element.companyProductSpecificationData.companyName,
        companyProductSpecificationId: element.companyProductSpecificationData.id,
        companyProductSpecificationName: element.companyProductSpecificationData.name,
        productVariationId: element.companyProductSpecificationData.productVariationId,
        productVariationName: element.companyProductSpecificationData.productVariationName,
        quantity: element.cartData.quantity,
        totalPrice: element.productTotalPrice,
        unitPrice: element.productPrice,
        unitType: 0,
        unitTypeName: "null"
      })
    })
    this.orderService.AddOrder(this.addOrderModel).subscribe({
      next: response => {
        if (!response.isSuccess) {
          this.alertService.AlertMessage(response.statusMessage)
          return;
        }
        this.alertService.AddAlertWithLink(response,"/siparis-tamam");
      }
    })
  }
}
