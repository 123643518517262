import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-testimonials',
    templateUrl: './testimonials.component.html',
    styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            subTitle: "YORUMLAR",
            title: "Müşterilerimiz Bizim İçin ne Dedi"
        }
    ]
    singleTestimonialsItem = [
        {
            feedback: "Fiyatları uygun ve kaliteli ürünler sunuyorlar. Her seferinde aradığım ürünü bulmak için saatlerce arama yapmama gerek kalmıyor. Ayrıca, satın aldığım her ürünün beklediğim gibi olması ve zamanında teslim edilmesi güvenilir bir alışveriş ortamı sağlıyor. Bu siteyi tanıdıktan sonra başka yerde alışveriş yapma ihtiyacı duymuyorum.",
            authorImg: 'assets/img/user1.jpg',
            authorName: 'Jason Doe',
            authorDesignation: 'Web Developer'
        },
        {
            feedback: "Bu site, sunduğu geniş ürün yelpazesi ve hızlı kargo hizmetiyle benim favorim haline geldi. Aradığım her şeyi bulabiliyorum ve satın aldığım ürünlerin hemen teslim edilmesi benim için büyük bir artı. Alışveriş yaparken her zaman keyif alıyorum!",
            authorImg: 'assets/img/user2.jpg',
            authorName: 'Sarah Taylor',
            authorDesignation: 'Web Developer'
        }
    ]

    testimonialsSlidesOptions: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		animateOut: 'fadeOut',
		animateIn: 'fadeIn',
		autoplay: true,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
                items: 1
			},
			576: {
                items: 1
			},
			768: {
                items: 1
			},
			992: {
                items: 1
			},
			1200: {
                items: 1
			}
		}
    }

}