import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutPageComponent } from './components/pages/about-page/about-page.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { CartPageComponent } from './components/pages/cart-page/cart-page.component';
import { CheckoutPageComponent } from './components/pages/checkout-page/checkout-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { CustomerServicePageComponent } from './components/pages/customer-service-page/customer-service-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { GalleryPageComponent } from './components/pages/gallery-page/gallery-page.component';
import { HomeDemoOneComponent } from './components/pages/home-demo-one/home-demo-one.component';
import { MyAccountPageComponent } from './components/pages/my-account-page/my-account-page.component';
import { NotFoundPageComponent } from './components/pages/not-found-page/not-found-page.component';
import { OrderTrackingPageComponent } from './components/pages/order-tracking-page/order-tracking-page.component';
import { RoomsPageComponent } from './components/pages/rooms-page/rooms-page.component';
import { SimpleProductPageComponent } from './components/pages/simple-product-page/simple-product-page.component';
import { WishlistPageComponent } from './components/pages/wishlist-page/wishlist-page.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { FavoriteComponent } from './components/pages/favorite/favorite.component';
import { CustomerAddressesComponent } from './components/pages/customer-addresses/customer-addresses.component';
import { OrdersPageComponent } from './components/pages/orders-page/orders-page.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ReturnPolicyComponent } from './components/pages/return-policy/return-policy.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { ForgetedPasswordComponent } from './components/pages/forgeted-password/forgeted-password.component';
import { AddOrderComponent } from './components/pages/add-order/add-order.component';
import { OrderisDonePageComponent } from './components/pages/orderis-done-page/orderis-done-page.component';

const routes: Routes = [
    { path: '', component: HomeDemoOneComponent },
    { path: 'about', component: AboutPageComponent },
    { path: 'ürünler/:categoryId/:categoryName', component: ProductsComponent },
    { path: 'simple-product/:productId/:categoryId/:categoryName', component: SimpleProductPageComponent },
    { path: 'rooms', component: RoomsPageComponent },
    { path: 'customer-service', component: CustomerServicePageComponent },
    { path: 'account', component: MyAccountPageComponent },
    { path: 'order-tracking', component: OrderTrackingPageComponent },
    { path: 'gallery', component: GalleryPageComponent },
    { path: 'cart', component: CartPageComponent },
    { path: 'checkout', component: CheckoutPageComponent },
    { path: 'wishlist', component: WishlistPageComponent },
    { path: 'blog-grid', component: BlogGridComponent },
    { path: 'blog-right-sidebar', component: BlogRightSidebarComponent },
    { path: 'blog-details', component: BlogDetailsComponent },
    { path: 'faq', component: FaqPageComponent },
    { path: 'coming-soon', component: ComingSoonPageComponent },
    { path: 'contact', component: ContactPageComponent },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'favorite', component: FavoriteComponent },
    { path: 'customer-addresses', component: CustomerAddressesComponent },
    { path: 'orders', component: OrdersPageComponent },
    { path: 'gizlilik-politikası', component: PrivacyPolicyComponent },
    { path: 'şartlar&koşullar', component: ReturnPolicyComponent },
    { path: 'iade-politikası', component: TermsConditionsComponent },
    { path: 'sifremi-unuttum', component: ForgetedPasswordComponent },
    { path: 'siparis-tamamla', component: AddOrderComponent },
    { path: 'siparis-tamam', component: OrderisDonePageComponent },



    { path: '**', component: NotFoundPageComponent } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }