import { Component, OnInit } from '@angular/core';
import { apiConst } from 'src/app/constants/apiConst';
import { optionMapConst } from 'src/app/constants/optionMapConst';
import { SiteInformation, SiteInformationApiModel } from 'src/app/models/siteInformationModel';
import { SiteInformationService } from 'src/app/services/siteInformation';

@Component({
  selector: 'app-orderis-done-page',
  templateUrl: './orderis-done-page.component.html',
  styleUrls: ['./orderis-done-page.component.scss']
})
export class OrderisDonePageComponent implements OnInit {

  siteInformation: SiteInformation[]
  siteInformationApiModel: SiteInformationApiModel;

  constructor(private siteInformationService: SiteInformationService) { }

  ngOnInit(): void {
    this.getPageInfos();
  }

  getPageInfos() {
    this.siteInformationService.GetSiteInformationByType({ type: [optionMapConst.orderDone], userId: apiConst.userId }).subscribe({
      next: datas => {
        this.siteInformationApiModel = datas.data;
        this.siteInformation = this.siteInformationApiModel.siteInformationsData;
        this.siteInformation[0].siteInformationPhotos[0].photoString = "https://eirc.com.tr/assets/img/"+this.siteInformation[0].siteInformationPhotos[0].photoString
      }
    })
  }

   
}
