<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{photoString}});">
    <h1>{{Content.title}}</h1>
    <ul>
        <li><a routerLink="/">Anasayfa</a></li>
        <li>{{Content.title}}</li>
    </ul>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area pt-100">
    <div class="container">
        <div class="contact-inner-area">
            <div class="row">
                <div class="col-lg-7 col-md-12">
                    <div class="contact-form">
                        <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <input required minlength="3" maxlength="50" ngModel name="name" type="text"
                                            #name="ngModel" class="form-control" id="name" placeholder="Ad soyad">
                                        <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                            <div *ngIf="name.errors.required">Lütfen bu alanı doldurunuz.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="email" type="text" #email="ngModel"
                                            class="form-control" id="email" placeholder="E-mail adresi">
                                        <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email
                                            Adresi gereklidir.</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="number" type="text" #number="ngModel"
                                            class="form-control" id="number" placeholder="Telefon numarası">
                                        <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Telefon
                                            numarası gereklidir.</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <textarea required ngModel #message="ngModel" name="message" id="message"
                                            cols="30" rows="6" class="form-control" placeholder="Mesajınız"></textarea>
                                        <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Lütfen bu
                                            alanı doldurunuz.</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid"><i
                                            class='bx bx-paper-plane'></i>Mesaj Gönder</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12">
                    <div class="contact-info-box mt-3">
                        <div class="icon">
                            <i class='bx bx-map'></i>
                        </div>
                        <h3>Adres</h3>
                        <p>{{contactInfoData?.address}}</p>
                    </div>
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class='bx bx-phone-call'></i>
                        </div>
                        <h3>İletişim</h3>
                        <p>Telefon: <a href="tel:+{{contactInfoData?.phoneNumber}}">{{contactInfoData?.phoneNumber}}</a>
                        </p>
                        <p>E-mail: <a href="mailto:{{contactInfoData?.email}}">{{contactInfoData?.email}}</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->

<!-- Start Map Area -->
<div id="map">
  
    <iframe [src]="mapUrl" width="600" height="450" style="border:0;" allowfullscreen=""
        loading="lazy"  referrerpolicy="no-referrer-when-downgrade"></iframe>
        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d48838.41710575923!2d29.508816000000003!3d40.08877!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cbcbd8e986fd0d%3A0x99f1566ef2dcf77d!2s%C4%B0remcan%20Mutfak!5e0!3m2!1str!2str!4v1706861865118!5m2!1str!2str" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
</div>
<!-- End Map Area -->