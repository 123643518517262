import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from 'ngx-spinner';
import { apiConst } from 'src/app/constants/apiConst';
import { optionMapConst } from 'src/app/constants/optionMapConst';
import { ContactInfoApiModel, ContactInfoModel, SiteInformation, SiteInformationApiModel, SiteInformationPhotos, SiteInformationRequestModel } from 'src/app/models/siteInformationModel';
import { SiteInformationService } from 'src/app/services/siteInformation';

@Component({
    selector: 'app-homeone-banner',
    templateUrl: './homeone-banner.component.html',
    styleUrls: ['./homeone-banner.component.scss']
})
export class HomeoneBannerComponent implements OnInit {

    siteInformationApiModel: SiteInformationApiModel
    siteInformationList: SiteInformation[] = [];

    contactInfoApiModel: ContactInfoApiModel;
    contactInfoData: ContactInfoModel
    sliderList: SiteInformation = null;
    constructor(private spinner: NgxSpinnerService, private siteInformationService: SiteInformationService, private webSiteInfoService: SiteInformationService) { }

    ngOnInit(): void {
        this.spinner.show();
        let siteInformationRequestModel: SiteInformationRequestModel = { type: [optionMapConst.contactInfo, optionMapConst.mail, optionMapConst.address,], userId: apiConst.userId }
        this.siteInformationService.GetSiteInformationByType(siteInformationRequestModel).subscribe({
            next: datas => {
                this.siteInformationApiModel = datas.data;
                this.siteInformationList = this.siteInformationApiModel.siteInformationsData
            }
        })
        this.siteInformationService.GetSiteInformationByType({ type: [optionMapConst.slider], userId: apiConst.userId }).subscribe({
            next: datas => {
                let siteInformationApiModel: SiteInformationApiModel
                siteInformationApiModel = datas.data;
                this.sliderList = siteInformationApiModel.siteInformationsData[0]
                this.sliderList.siteInformationPhotos.forEach((element) => {
                    element.photoString = 'https://eirc.com.tr/assets/img/' + element.photoString;
                })
            }
        })
        this.webSiteInfoService.GetContactInfo(apiConst.userId).subscribe({
            next: datas => {
                this.contactInfoApiModel = datas.data;
                this.contactInfoData = this.contactInfoApiModel.contactInfoData
                this.spinner.hide();
            }
        })
    }

    bannerSocialLinks = [
        {
            icon: 'bx bxl-facebook',
            link: '#'
        },
        {
            icon: 'bx bxl-twitter',
            link: '#'
        },
        {
            icon: 'bx bxl-linkedin',
            link: '#'
        },
        {
            icon: 'bx bxl-instagram',
            link: '#'
        }
    ]
    bannerContactInfo = [
        {
            number: '+44 458 895 456',
            email: 'hello@fiwan.com'
        }
    ]

    homeSlidesOptions: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        margin: 5,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>"
        ],
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 1
            },
            768: {
                items: 1
            },
            992: {
                items: 1
            },
            1200: {
                items: 1
            }
        }
    }

}