import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GalleryPageComponent } from './components/pages/gallery-page/gallery-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { HomeDemoOneComponent } from './components/pages/home-demo-one/home-demo-one.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { HomeoneBannerComponent } from './components/pages/home-demo-one/homeone-banner/homeone-banner.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { TestimonialsComponent } from './components/common/testimonials/testimonials.component';
import { HomeoneCategoriesComponent } from './components/pages/home-demo-one/homeone-categories/homeone-categories.component';
import { FacilityStyleOneComponent } from './components/common/facility-style-one/facility-style-one.component';
import { DealInThisWeekComponent } from './components/common/deal-in-this-week/deal-in-this-week.component';
import { FacilityStyleTwoComponent } from './components/common/facility-style-two/facility-style-two.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { NotFoundPageComponent } from './components/pages/not-found-page/not-found-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { OrderTrackingPageComponent } from './components/pages/order-tracking-page/order-tracking-page.component';
import { MyAccountPageComponent } from './components/pages/my-account-page/my-account-page.component';
import { UpcomingProductsComponent } from './components/common/upcoming-products/upcoming-products.component';
import { TrendingProductsComponent } from './components/common/trending-products/trending-products.component';
import { PopularProductsComponent } from './components/common/popular-products/popular-products.component';
import { NewProductsComponent } from './components/common/new-products/new-products.component';
import { DiscountComponent } from './components/common/discount/discount.component';
import { SimpleProductPageComponent } from './components/pages/simple-product-page/simple-product-page.component';
import { CartPageComponent } from './components/pages/cart-page/cart-page.component';
import { CheckoutPageComponent } from './components/pages/checkout-page/checkout-page.component';
import { WishlistPageComponent } from './components/pages/wishlist-page/wishlist-page.component';
import { AboutPageComponent } from './components/pages/about-page/about-page.component';
import { VideoComponent } from './components/common/video/video.component';
import { WhyChooseUsComponent } from './components/common/why-choose-us/why-choose-us.component';
import { RoomsPageComponent } from './components/pages/rooms-page/rooms-page.component';
import { LivingRoomProductsComponent } from './components/pages/rooms-page/living-room-products/living-room-products.component';
import { BedroomProductsComponent } from './components/pages/rooms-page/bedroom-products/bedroom-products.component';
import { KitchenRoomProductsComponent } from './components/pages/rooms-page/kitchen-room-products/kitchen-room-products.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { FavoriteComponent } from './components/pages/favorite/favorite.component';
import { ToastrModule, provideToastr } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { CustomerAddressesComponent } from './components/pages/customer-addresses/customer-addresses.component';
import { OrdersPageComponent } from './components/pages/orders-page/orders-page.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { FilterPipe } from './pipe/filter.pipe';
import { CustomerInfoComponent } from './components/pages/customer-info/customer-info.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ReturnPolicyComponent } from './components/pages/return-policy/return-policy.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { BrandsSliderComponent } from './components/common/brands-slider/brands-slider.component';
import { ForgetedPasswordComponent } from './components/pages/forgeted-password/forgeted-password.component';
import { AddOrderComponent } from './components/pages/add-order/add-order.component';
import { OrderisDonePageComponent } from './components/pages/orderis-done-page/orderis-done-page.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PasswordUpdateComponent } from './components/common/password-update/password-update.component';
import { OrderListComponent } from './components/common/order-list/order-list.component';
import { AddressCommonComponent } from './components/common/address-common/address-common.component';


@NgModule({
  declarations: [
    AppComponent,
    GalleryPageComponent,
    ContactPageComponent,
    HomeDemoOneComponent,
    NavbarStyleOneComponent,
    HomeoneBannerComponent,
    PartnerComponent,
    TestimonialsComponent,
    HomeoneCategoriesComponent,
    FacilityStyleOneComponent,
    DealInThisWeekComponent,
    FacilityStyleTwoComponent,
    SubscribeComponent,
    BlogComponent,
    FooterComponent,
    BlogGridComponent,
    BlogDetailsComponent,
    BlogRightSidebarComponent,
    NotFoundPageComponent,
    ComingSoonPageComponent,
    FaqPageComponent,
    OrderTrackingPageComponent,
    MyAccountPageComponent,
    UpcomingProductsComponent,
    TrendingProductsComponent,
    PopularProductsComponent,
    NewProductsComponent,
    DiscountComponent,
    SimpleProductPageComponent, 
    CartPageComponent,
    CheckoutPageComponent,
    WishlistPageComponent,
    AboutPageComponent,
    VideoComponent,
    WhyChooseUsComponent,
    RoomsPageComponent,
    LivingRoomProductsComponent,
    BedroomProductsComponent,
    KitchenRoomProductsComponent, 
    LoginComponent,
    RegisterComponent,
    FavoriteComponent,
    CustomerAddressesComponent,
    OrdersPageComponent,
    ProductsComponent,
    FilterPipe,
    CustomerInfoComponent,
    PrivacyPolicyComponent,
    ReturnPolicyComponent,
    TermsConditionsComponent, 
    BrandsSliderComponent, ForgetedPasswordComponent, AddOrderComponent, OrderisDonePageComponent, PasswordUpdateComponent, OrderListComponent, AddressCommonComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    NgxScrollTopModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SelectDropDownModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    NgxPaginationModule
  ],
  providers: [
    provideAnimations(), // required animations providers
    provideToastr(), // Toastr providers
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
