import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CompanyProductModel, ProductShopResponseModel } from 'src/app/models/productModel';
import { ProductService } from 'src/app/services/product.service';
import { SiteInformationService } from 'src/app/services/siteInformation';

@Component({
    selector: 'app-trending-products',
    templateUrl: './trending-products.component.html',
    styleUrls: ['./trending-products.component.scss']
})
export class TrendingProductsComponent implements OnInit {
    productShopResponseModel: ProductShopResponseModel;
    companyProductData: CompanyProductModel[]
    constructor(private productService: ProductService, private siteInformationService: SiteInformationService) { }

    ngOnInit(): void {
        this.productService.GetAllProductShop({ userId: "3fa85f64-5717-4562-b3fc-2c963f66afa6", orderByPrice: true }).subscribe({
            next: datas => {
                this.productShopResponseModel = datas.data;
                this.companyProductData = this.productShopResponseModel.productShopsData;
                this.companyProductData.forEach((element) => {
                    element.photoString = "https://eirc.com.tr/assets/img/" + element.photoString
                })
            }
        })
    }

    sectionTitle = [
        {
            title: "Bu Haftanın Trendleri",
            paragraph: "Tarzınıza Yenilik Katmanın Tam Zamanı, Bu Haftanın Trendlerini Kaçırmayın!"
        }
    ]
    singleProductsBox = [
        {
            mainImg: 'assets/img/products/products4.jpg',
            title: 'Ergonomic Desk Sofa',
            newPrice: '$150.00',
            oldPrice: '',
            outOfStock: 'Out of Stock',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products5.jpg',
            title: 'Office Desk Sofa',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: 'Sale!',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products6.jpg',
            title: 'Swivel Sofa',
            newPrice: '$223.99',
            oldPrice: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products7.jpg',
            title: 'Home Alisa Sofa',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            outOfStock: '',
            detailsLink: 'simple-product'
        }
    ]

    productsSlidesOptions: OwlOptions = {
        loop: true,
        nav: false,
        dots: true,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 30,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>"
        ],
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1200: {
                items: 3
            }
        }
    }

}