import { Injectable } from '@angular/core';
import { apiConst } from '../constants/apiConst';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListResponseModel } from '../models/response-models/listResponseModel';
import { ContactInfoModel, SiteInformation, SiteInformationRequestModel } from '../models/siteInformationModel';
import { StorageService } from './reactiveStorageService';

@Injectable({
  providedIn: 'root'
})
export class SiteInformationService {
  baseUrl = apiConst.baseApiLink;
  userId = apiConst.userId

  constructor(private httpClient: HttpClient, private localStorage: StorageService) { 
    let userId = localStorage.getUserId();
    if (userId != null) {
      this.userId = userId
    }
  }

  GetSiteInformationByType(siteInformationRequestModel: SiteInformationRequestModel): Observable<ListResponseModel<SiteInformation>> {
    siteInformationRequestModel.userId = this.userId;
    return this.httpClient.post<ListResponseModel<SiteInformation>>(this.baseUrl + "SiteInformation/GetSiteInformationByType", siteInformationRequestModel)
  }

  GetContactInfo(userId: string): Observable<ListResponseModel<ContactInfoModel>> {
    return this.httpClient.post<ListResponseModel<ContactInfoModel>>(this.baseUrl + "SiteInformation/GetContactInfo", { userId: userId })
  }
}
