<!-- <app-navbar-style-one></app-navbar-style-one> -->

<!-- Start Page Title Area -->
<div class="page-title-area" style="background-image: url('{{photoString}}');">
    <h1>Gizlilik Politikası</h1>
    <ul>
        <li><a routerLink="/">Ana Sayfa</a></li>
        <li>Gizlilik Politikası</li>
    </ul>
</div>
<!-- End Page Title Area -->

<!-- Start Customer Service Area -->
<section class="customer-service-area ptb-100">
    <div class="container">
        <div class="customer-service-content">
            <p [innerHTML]="pageText">

            </p>
        </div>
    </div>
</section>
<!-- End Customer Service Area -->