import { Injectable } from "@angular/core"; 
import { ResponseModel } from "../models/response-models/responseModel";
import { Router } from "@angular/router"; 
import { Observable, from, of, timer } from "rxjs";
import { catchError, first, switchMap } from "rxjs/operators";
import Swal from "sweetalert2";
@Injectable({
    providedIn: "root"
})
export class AlertService {
    source = 1500


    constructor(private router: Router) { }

    goToLink = (link: string) => {
        this.router.navigate([link])
    }

    AddAlertWithLink(datas: ResponseModel, link: string) {
        let iconType;
        if (datas.isSuccess) {
            iconType = 'success'
            timer(this.source).pipe(first()).subscribe(() => {
                this.goToLink(link)
            })
        } else {
            iconType = 'error'
        }
        Swal.fire({
            text: datas.statusMessage,
            icon: iconType,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500
        })
    }

    AddAlert(datas: ResponseModel) {
        let iconType;
        if (datas.isSuccess) {
            iconType = 'success'

        } else {
            iconType = 'error'
        }
        Swal.fire({
            text: datas.statusMessage,
            icon: iconType,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500
        })
    }



    AddAlertObserver(datas: ResponseModel): Observable<boolean> {
        let iconType;
        if (datas.isSuccess) {
            iconType = 'success'

        } else {
            iconType = 'error'
        }

        return from(
            Swal.fire({
                text: datas.statusMessage,
                icon: iconType,
                position: 'top-end',
                showConfirmButton: false,
                timer: 1500
            })
        ).pipe(
            switchMap((response) => {
                if (response.isConfirmed) {
                    return of(true);
                } else {
                    // İptal edildiyse veya hata oluştuysa false döndür
                    return of(false);
                }
            }),
            catchError((error) => {
                console.error('Hata oluştu:', error);
                // Hata durumunda false döndür
                return of(false);
            })
        );
    }

    DeleteAlert(dataName: string): Observable<boolean> {
        return from(
            Swal.fire({
                title: 'Emin misiniz?',
                text: dataName + ' verisini silmek istediğinizden emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet, sil!',
                cancelButtonText: 'Hayır, vazgeç',
                reverseButtons: false,
            })
        ).pipe(
            switchMap((response) => {
                if (response.isConfirmed) {
                    return of(true);
                } else {
                    // İptal edildiyse veya hata oluştuysa false döndür
                    return of(false);
                }
            }),
            catchError((error) => {
                console.error('Hata oluştu:', error);
                // Hata durumunda false döndür
                return of(false);
            })
        );
    }

    ChangeAlert(dataName: string): Observable<boolean> {
        return from(
            Swal.fire({
                title: 'Emin misiniz?',
                text: dataName + ' durumunu değiştirmek istediğinizden emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet, değiştir!',
                cancelButtonText: 'Hayır, vazgeç',
                reverseButtons: false,
            })
        ).pipe(
            switchMap((response) => {
                if (response.isConfirmed) {
                    // Silme işlemi yapılacaksa true döndür
                    return of(true);
                } else {
                    // İptal edildiyse veya hata oluştuysa false döndür
                    return of(false);
                }
            }),
            catchError((error) => {
                return of(false);
            })
        );
    }



    ProductSelect(dataName: string): Observable<boolean> {
        return from(
            Swal.fire({
                title: 'Emin misiniz?',
                text: dataName + ' seçilecek emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet, seç!',
                cancelButtonText: 'Hayır, vazgeç',
                reverseButtons: false,
            })
        ).pipe(
            switchMap((response) => {
                if (response.isConfirmed) {
                    // Silme işlemi yapılacaksa true döndür
                    Swal.fire({
                        text: dataName + ' Seçildi',
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    return of(true);
                } else {
                    // İptal edildiyse veya hata oluştuysa false döndür
                    return of(false);
                }
            }),
            catchError((error) => {
                return of(false);
            })
        );
    }

    InfoMessage(message: string) {
        Swal.fire({
            text: message,
            icon: 'info',
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500
        })
    }
    SuccessMessage(message: string) {
        Swal.fire({
            text: message,
            icon: 'success',
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500
        })
    }
    AlertMessage(message: string) {
        Swal.fire({
            text: message,
            icon: 'warning',
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500
        })
    }

    DeletedAlert(response: ResponseModel) {
        let icon;
        if (response.isSuccess) {
            icon = "success";
        } else {
            icon = "warning";
        }

        Swal.fire({
            text: response.statusMessage,
            icon: icon,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500
        })
    }


}