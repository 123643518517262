import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProductShopResponseModel, CompanyProductModel } from 'src/app/models/productModel';
import { ProductService } from 'src/app/services/product.service';
import { SiteInformationService } from 'src/app/services/siteInformation';

@Component({
    selector: 'app-popular-products',
    templateUrl: './popular-products.component.html',
    styleUrls: ['./popular-products.component.scss']
})
export class PopularProductsComponent implements OnInit {

    productShopResponseModel: ProductShopResponseModel;
    companyProductData: CompanyProductModel[]
    constructor(private productService: ProductService, private siteInformationService: SiteInformationService) { }

    ngOnInit(): void {
        this.productService.GetAllProductShop({ userId: "3fa85f64-5717-4562-b3fc-2c963f66afa6", orderByPrice: true }).subscribe({
            next: datas => {
                this.productShopResponseModel = datas.data;
                this.companyProductData = this.productShopResponseModel.productShopsData;
                this.companyProductData.forEach((element) => {
                    element.photoString = "https://eirc.com.tr/assets/img/" + element.photoString
                })
            }
        })
    }
    sectionTitle = [
        {
            title: "Popüler Ürünler",
            paragraph: "Şıklığı Yakalayın. Popüler Ürünlerle Tarzınızı Güçlendirin! En Beğenilenler Şimdi Burada, Hemen Keşfedin!"
        }
    ]
    singleProductsBox = [
        {
            mainImg: 'assets/img/products/products7.jpg',
            title: 'Fabric Arm Sofa',
            newPrice: '$220.00',
            oldPrice: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products8.jpg',
            title: 'Grey Harrington Sofa',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            sale: 'Sale!',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products9.jpg',
            title: 'Home Alisa Sofa',
            newPrice: '$223.99',
            oldPrice: '',
            outOfStock: '',
            detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/products/products6.jpg',
            title: 'Ergonomic Desk Sofa',
            newPrice: '$199.00',
            oldPrice: '$210.00',
            outOfStock: 'Out of Stock',
            detailsLink: 'simple-product'
        }
    ]

    productsSlidesOptions: OwlOptions = {
		loop: true,
		nav: false,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 2
			},
			768: {
				items: 2
			},
			992: {
				items: 3
			},
			1200: {
				items: 3
			}
		}
    }

}