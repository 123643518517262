<div class="container">
    <div class="section-title" *ngFor="let Content of sectionTitle;">
        <h2>{{Content.title}}</h2>
        <p>{{Content.paragraph}}</p>
    </div>

    <div class="products-slides">
        <owl-carousel-o [options]="productsSlidesOptions">
            <ng-template carouselSlide *ngFor="let Content of companyProductData;">
                <div class="single-products-box">
                    <div class="products-image">
                        <a class="d-block"
                            [queryParams]="{productId: Content.companyProductData.id,categoryId,categoryName}"
                            [routerLink]="['/simple-product',Content.companyProductData.id,categoryId,categoryName]">
                            <img src="{{Content.photoString}}" alt="image" style="width: 790px;">
                        </a>
                        <ul class="products-button">
                            <li><a routerLink="/wishlist"><i class='bx bx-heart'></i></a></li>
                            <li><a href="#" data-bs-toggle="modal" data-bs-target="#productsQuickView"><i
                                        class='bx bx-show-alt'></i></a></li>
                            <li><a href="assets/img/products/products4.jpg" class="popup-btn"><i
                                        class='bx bx-scan'></i></a></li>
                            <li><a [queryParams]="{productId: Content.companyProductData.id,categoryId,categoryName}"
                                    [routerLink]="['/simple-product',Content.companyProductData.id,categoryId,categoryName]"><i
                                        class='bx bx-link'></i></a></li>
                        </ul>
                        <button style="border: 0px;"
                            [queryParams]="{productId: Content.companyProductData.id,categoryId,categoryName}"
                            [routerLink]="['/simple-product',Content.companyProductData.id,categoryId,categoryName]"
                            class="add-to-cart-btn"><i class="bx bx-cart"></i>İncele</button>
                    </div>
                    <div class="products-content">
                        <h3><a [queryParams]="{productId: Content.companyProductData.id,categoryId,categoryName}"
                                [routerLink]="['/simple-product',Content.companyProductData.id,categoryId,categoryName]">{{Content.companyProductData.title}}</a>
                        </h3>
                        <div class="price">
                            <span class="new-price">{{Content.totalPrice}} TL</span>
                            <span class="old-price" *ngIf="Content.oldPrice">{{Content.totalPrice}} TL</span>
                        </div>
                    </div>
                    <span class="on-sale" *ngIf="Content.sale">{{Content.sale}}</span>
                    <div class="out-of-stock" *ngIf="Content.outOfStock">{{Content.outOfStock}}</div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>