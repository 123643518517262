import { Injectable } from '@angular/core';
import { apiConst } from '../constants/apiConst';
import { HttpClient } from '@angular/common/http';
import { ListResponseModel } from '../models/response-models/listResponseModel';
import { Observable } from 'rxjs';
import { CategoryModel } from '../models/categoryModel';
import { StorageService } from './reactiveStorageService';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  baseUrl = apiConst.baseApiLink;
  userId =  apiConst.userId
  
  constructor(private httpClient: HttpClient, private localStorage: StorageService) {
    let userId = localStorage.getUserId();
    if (userId != null) {
      this.userId = userId
    }
   }

  GetAllCategoryList(): Observable<ListResponseModel<CategoryModel>> {
    return this.httpClient.post<ListResponseModel<CategoryModel>>(this.baseUrl + "Specification/GetAllCategoryList", { userId: this.userId })
  }

  CategoryGetById(id: string): Observable<ListResponseModel<CategoryModel>> {
    return this.httpClient.post<ListResponseModel<CategoryModel>>(this.baseUrl + "Specification/CategoryGetById", { userId: this.userId, id: id })
  }

  GetAllCategoryPropertyList(categoryId: string): Observable<ListResponseModel<CategoryModel>> {
    return this.httpClient.post<ListResponseModel<CategoryModel>>(this.baseUrl + "Specification/GetAllCategoryPropertyList", { userId: this.userId, categoryId: categoryId })
  }

  GetSubCategory(categoryId: string): Observable<ListResponseModel<CategoryModel>> {
    return this.httpClient.post<ListResponseModel<CategoryModel>>(this.baseUrl + "Specification/CategoryGetById", { userId: this.userId, categoryId: categoryId })
  }
}
