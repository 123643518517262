<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" style="background-image: url({{siteInformationList[0].siteInformationPhotos[0].photoString}});"> 
    <h1>Hakkımızda</h1>
    <ul>
        <li><a routerLink="/">Anasayfa</a></li>
        <li>Hakkımızda</li>
    </ul>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area pt-100 pb-70">
    <div class="container">
        <div class="about-content">
            <h2>{{siteInformationList[0]?.title}}</h2>
            <p>{{siteInformationList[0]?.text}}</p>
        </div>
    </div>
</section>