import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { apiConst } from 'src/app/constants/apiConst';
import { CustomerData, CustomerModel } from 'src/app/models/customermodel';
import { CustomerService } from 'src/app/services/customer.service';
import { StorageService } from 'src/app/services/reactiveStorageService';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  customerData: CustomerModel;
  customerFormApi: CustomerData;

  constructor(
    private _formBuilder: FormBuilder,
    private customerService: CustomerService,
    private toastrService: ToastrService,
    private router: Router,
    private ractiveLocalStorage: StorageService
  ) { }

  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      mailAddress: ["", [Validators.required]],
      password: ["", [Validators.required]],
    })
  }

  login() {
    this.customerService.Login(this.loginForm.value).subscribe(response => {
      if (response.isSuccess) {
        this.customerData = response.data
        this.customerFormApi = this.customerData.customerData;
        this.ractiveLocalStorage.set(apiConst.customerId, this.customerFormApi.id);
        this.ractiveLocalStorage.set(apiConst.customerName, this.customerFormApi.customerName);
        this.toastrService.success(response.statusMessage, "Başarılı")
        this.router.navigate(['/']).then(()=>{
          window.location.reload()
        })
      } else {
        this.toastrService.error(response.statusMessage, "Başarısız")
      }
    })
  }

}
