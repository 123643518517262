import { Injectable } from "@angular/core";
import { apiConst } from "../constants/apiConst";
import { HttpClient } from "@angular/common/http";
import { CartDataApiModel, CartModel, CartResponseModel } from "../models/cartmodel";
import { Observable } from "rxjs";
import { ResponseModel } from "../models/response-models/responseModel";
import { ListResponseModel } from "../models/response-models/listResponseModel";
import { SingleResponseModel } from "../models/response-models/singleResponseModel";
import { StorageService } from "./reactiveStorageService";

@Injectable({
    providedIn: 'root'
})
export class CartService {
    baseUrl = apiConst.baseApiLink;
    userId = this.localStorage.getUserId();

    constructor(private httpClient: HttpClient, private localStorage: StorageService) { }

    AddCart(cartResponseModel: CartResponseModel): Observable<ResponseModel> {
        cartResponseModel.createdUserId = this.userId
        return this.httpClient.post<ResponseModel>(this.baseUrl + "Order/AddCart", cartResponseModel);
    }

    CartGetByCustomerId(customerId: string): Observable<ListResponseModel<CartModel>> {
        return this.httpClient.post<ListResponseModel<CartModel>>(this.baseUrl + "Order/CartGetByCustomerId", { customerId: customerId, userId: this.userId })
    }

    UpdateCart(cartId: string, quantity: number): Observable<SingleResponseModel<CartDataApiModel>> {
        return this.httpClient.post<SingleResponseModel<CartDataApiModel>>(this.baseUrl + "Order/UpdateCart", { cartId: cartId, quantity: quantity, updatedUserId: this.userId })
    }

    DeleteCartItem(cartId: string): Observable<ResponseModel> {
        return this.httpClient.post<ResponseModel>(this.baseUrl + "Order/CartChangeStatus", { cartId: cartId, status: 99, updatedUserId: this.userId })
    }
}