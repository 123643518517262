<!-- Start Main Banner Area -->
<div class="home-banner-area">
    <div class="container-fluid">
        <div class="home-slides">
            <owl-carousel-o [options]="homeSlidesOptions">
                <ng-template carouselSlide *ngFor="let Content of sliderList?.siteInformationPhotos;">
                    <div class="main-banner-area">
                        <div class="row">
                            <div class="col-lg-5 col-md-12">
                                <div class="main-banner-content">
                                    <h1>{{Content.title}}</h1>
                                    <p [innerHTML]="Content.text"></p>
                                    <a routerLink="/{{Content.link}}" class="default-btn"><i
                                            class="bx bx-shopping-bag"></i> İncele</a>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-12">
                                <div class="main-banner-image text-center"
                                    style="background-image: url({{Content.photoString}});">
                                    <img [src]="Content.photoString" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>

    <ul class="banner-social-links">
        <li *ngFor="let Links of bannerSocialLinks;"><a href="{{Links.link}}" target="_blank"><i
                    class="{{Links.icon}}"></i></a></li>
    </ul>

    <div class="banner-contact-info">
        <ul *ngFor="let Info of bannerContactInfo;">
            <li><a href="tel:{{contactInfoData?.phoneNumber}}"><i class='bx bx-phone-call'></i>
                    {{contactInfoData?.phoneNumber}}</a></li>
            <li><a href="mailto:{{contactInfoData?.email}}"><i class='bx bx-envelope'></i>
                    {{contactInfoData?.email}}</a>
            </li>
        </ul>
    </div>
</div>
<!-- End Main Banner Area -->