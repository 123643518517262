import { Injectable } from '@angular/core';
import { apiConst } from '../constants/apiConst';

@Injectable({
    providedIn: 'root'
})

export class StorageService {

    constructor() { }

    getUserId() {
        return JSON.parse(localStorage.getItem(apiConst.userIdConst));
    }

    set(key: string, value: any): void {
        let val = JSON.stringify(value);
        localStorage.setItem(key, val);
    }

    get(key: string): any {
        return JSON.parse(localStorage.getItem(key));
    }

    remove(key: string): void {
        localStorage.removeItem(key);
    }
}