<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{bannerPhotoString}});">
    <h1>{{Content.title}}</h1>
    <ul>
        <li><a routerLink="/">Anasayfa</a></li>
        <li>{{Content.title}}</li>
    </ul>
</div>
<!-- End Page Title Area -->

<!-- Start Cart Area -->
<section class="cart-area ptb-100">
    <div class="container">
        <form>
            <div class="cart-table table-responsive">
                <div>
                    <a class="default-btn mb-4" data-bs-target="#exampleModalToggle" data-bs-toggle="modal" href="#"><i
                            class="bx bx-plus"></i> Adres
                        Ekle</a>

                </div>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Adres Adı</th>
                            <th scope="col">Adres</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let item of customerAddressData">
                            <td class="product-name">
                                <p>{{item.addressName}}</p>
                            </td>
                            <td class="product-price">
                                <p>{{item.address}}</p>
                            </td>
                            <td class="product-subtotal">
                                <a (click)="deleteCustomerAddress(item.id)" class="remove"><i class='bx bx-trash'></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>
    </div>
</section>
<!-- End Cart Area -->


<div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
    tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Adres Ekle</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form [formGroup]="addressForm" (ngSubmit)="addCustomerAddress()">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-10 container">
                            <input formControlName="addressName" type="text" class="form-control"
                                placeholder="Adres Adı">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-10 container">
                            <textarea formControlName="address" rows="5" type="text" class="form-control"
                                placeholder="Adres"></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" type="submit">Kaydet</button>
                </div>
            </form>
        </div>
    </div>
</div>