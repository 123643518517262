import { Injectable } from '@angular/core';
import { apiConst } from '../constants/apiConst';
import { HttpClient } from "@angular/common/http";
import { ListResponseModel } from '../models/response-models/listResponseModel';
import { Observable } from 'rxjs';
import { CompanyProductModel, ProductModel, ProductShopGetByCategoryIdRequestModel, ProductShopRequestModel, ProductShopResponseModel } from '../models/productModel';
import { CompanyProductDetailApiResponse } from '../models/companyproductdetailModel';
import { SingleResponseModel } from '../models/response-models/singleResponseModel';
import { StorageService } from './reactiveStorageService';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  baseUrl = apiConst.baseApiLink;
  userId = apiConst.userId

  constructor(private httpClient: HttpClient, private localStorage: StorageService) {

    let userId = localStorage.getUserId();
    if (userId != null) {
      this.userId = userId
    }
  }

  GetAllProductList(): Observable<ListResponseModel<ProductModel>> {
    return this.httpClient.post<ListResponseModel<ProductModel>>(this.baseUrl + "Product/GetProductAllList", { userId: this.userId })
  }

  ProductGetByCategoryId(categoryId: string): Observable<ListResponseModel<ProductModel>> {
    return this.httpClient.post<ListResponseModel<ProductModel>>(this.baseUrl + "Product/ProductGetByCategoryId", { userId: this.userId, categoryId: categoryId })
  }

  ProductGetByBrandModelId(brandModelId: string): Observable<ListResponseModel<ProductModel>> {
    return this.httpClient.post<ListResponseModel<ProductModel>>(this.baseUrl + "Product/ProductGetByBrandModelId", { userId: this.userId, brandModelId: brandModelId })
  }

  ProductVariationGetByProductId(productId: string): Observable<ListResponseModel<ProductModel>> {
    return this.httpClient.post<ListResponseModel<ProductModel>>(this.baseUrl + "Product/ProductVariationGetByProductId", { userId: this.userId, productId: productId })
  }

  GetAllProductShop(productShopRequestModel: ProductShopRequestModel): Observable<ListResponseModel<CompanyProductModel>> {
    productShopRequestModel.userId = this.userId;
    return this.httpClient.post<ListResponseModel<CompanyProductModel>>(this.baseUrl + "Shop/GetAllProductShop", productShopRequestModel)
  }

  ProductVariationShopGetByCompanyProductId(companyProductId: string): Observable<SingleResponseModel<CompanyProductDetailApiResponse>> {
    return this.httpClient.post<SingleResponseModel<CompanyProductDetailApiResponse>>(this.baseUrl + "Shop/ProductVariationShopGetByCompanyProductId", { companyProductId: companyProductId, userId: this.userId })
  }

  ProductShopGetByCategoryId(productShopGetByCategoryIdRequestModel: ProductShopGetByCategoryIdRequestModel): Observable<ListResponseModel<CompanyProductModel>> {
    productShopGetByCategoryIdRequestModel.userId = this.userId;
    return this.httpClient.post<ListResponseModel<CompanyProductModel>>(this.baseUrl + "Shop/ProductShopGetByCategoryId", productShopGetByCategoryIdRequestModel)
  }
}
