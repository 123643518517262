import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { apiConst } from "../constants/apiConst";
import { Observable } from "rxjs";
import { ResponseModel } from "../models/response-models/responseModel";
import { AddOrderModel } from "../models/orderModel";
import { StorageService } from "./reactiveStorageService";
import { ListResponseModel } from "../models/response-models/listResponseModel";

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    baseUrl = apiConst.baseApiLink;
    userId = this.localStorage.getUserId();

    constructor(private httpClient: HttpClient, private localStorage: StorageService) { }

    AddOrder(addOrderModel: AddOrderModel): Observable<ResponseModel> {
        addOrderModel.createdUserId = this.userId;
        return this.httpClient.post<ResponseModel>(this.baseUrl + "Order/AddOrder", addOrderModel);
    }

    OrderGetByCustomerId(): Observable<ListResponseModel<AddOrderModel>> {
        return this.httpClient.post<ListResponseModel<AddOrderModel>>(this.baseUrl + "Order/OrderGetByCustomerId", { customerId: this.userId, userId: this.userId })
    }

}