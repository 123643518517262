<app-navbar-style-one></app-navbar-style-one>

<!-- Start Profile Authentication Area -->
<section class="profile-authentication-area ptb-100 mt-5">
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-md-6 mt-5">
                <div class="login-form">
                    <h2 class="text-center">Şifremi Unuttum</h2>
                    <h5 class="text-center">Şifrenizi yenilemek için e-posta adresinize ihtiyacımız var.</h5>
                    <form>
                        <div class="form-group">
                            <label>E Posta</label>
                            <input formControlName="mailAddress" type="text" class="form-control"
                                placeholder="E posta adresiniz">
                        </div>
                        <button type="submit">Şifremi Yenile</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Start Profile Authentication Area -->