import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgeted-password',
  templateUrl: './forgeted-password.component.html',
  styleUrls: ['./forgeted-password.component.scss']
})
export class ForgetedPasswordComponent implements OnInit {
 
 
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

}
