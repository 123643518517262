import { Injectable } from '@angular/core';
import { apiConst } from '../constants/apiConst';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListResponseModel } from '../models/response-models/listResponseModel';
import { AddCustomerAddressResponseModel, CustomerAddressApiModel, CustomerData, CustomerModel } from '../models/customermodel';
import { ResponseModel } from '../models/response-models/responseModel';
import { SingleResponseModel } from '../models/response-models/singleResponseModel';
import { StorageService } from './reactiveStorageService';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  baseUrl = apiConst.baseApiLink;
  userId = this.localStorage.getUserId();

  constructor(private httpClient: HttpClient, private localStorage: StorageService) { }

  Register(customer: CustomerData): Observable<ResponseModel> {
    return this.httpClient.post<ResponseModel>(this.baseUrl + "Customer/AddCustomer", { firstName: customer.firstName, lastName: customer.lastName, customerName: customer.customerName, password: customer.password, mailAddress: customer.mailAddress, phoneNumber: customer.phoneNumber, createdUserId: this.userId })
  }

  Login(customer: CustomerData): Observable<SingleResponseModel<CustomerModel>> {
    return this.httpClient.post<SingleResponseModel<CustomerModel>>(this.baseUrl + "Customer/CustomerLogin", { customerName: customer.customerName, password: customer.password, mailAddress: customer.mailAddress })
  }

  CustomerDetail(customerId: string): Observable<ListResponseModel<CustomerModel>> {
    return this.httpClient.post<ListResponseModel<CustomerModel>>(this.baseUrl + "Customer/CustomerDetail", { userId: this.userId, customerId: customerId })
  }

  CustomerAddressGetByCustomerId(customerId: string): Observable<ListResponseModel<CustomerAddressApiModel>> {
    return this.httpClient.post<ListResponseModel<CustomerAddressApiModel>>(this.baseUrl + "Customer/CustomerAddressGetByCustomerId", { customerId: customerId, userId: this.userId })
  }

  CustomerAddressChangeStatus(customerAddressId: string): Observable<ResponseModel> {
    return this.httpClient.post<ResponseModel>(this.baseUrl + "Customer/CustomerAddressChangeStatus", { customerAddressId: customerAddressId, status: 99, updatedUserId: this.userId })
  }

  AddCustomerAddress(addCustomerAddressResponseModel: AddCustomerAddressResponseModel): Observable<ResponseModel> {
    addCustomerAddressResponseModel.createdUserId = this.userId;
    return this.httpClient.post<ResponseModel>(this.baseUrl + "Customer/AddCustomerAddress", addCustomerAddressResponseModel)
  }
}
