<!-- Start Navbar Area -->
<div class="navbar-area p-absolute">
    <div class="fiwan-responsive-nav">
        <div class="container">
            <div class="fiwan-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="fiwan-nav">
        <div class="container-fluid">
            <!-- Navbar -->
            <nav class="navbar navbar-expand-lg  navbar-light bg-light">
                <!-- Container wrapper -->
                <div class="container">
                    <!-- Navbar brand -->
                    <a class="navbar-brand" href="#"><img id="MDB-logo" src="assets/img/logo.png" alt="MDB Logo"
                            draggable="false" height="30" /></a>

                    <!-- Toggle button -->
                    <button class="navbar-toggler" type="button" data-mdb-toggle="collapse"
                        data-mdb-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <i class="fas fa-bars"></i>
                    </button>

                    <!-- Collapsible wrapper -->
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <form class="d-flex align-items-center w-100 form-search">
                            <div class="search-overlay-form">
                                <form>
                                    <input type="text" class="input-search" placeholder="Ara...">
                                    <button type="submit"><i class='bx bx-search'></i></button>
                                </form>
                            </div>
                        </form>
                        <ul class="navbar-nav ms-3">
                            <li class="nav-item" style="width: 55px;">
                                <a class="nav-link d-flex align-items-center me-3" routerLink="/cart">
                                    <i class="bx bx-shopping-bag me-1"></i> Sepet
                                </a>
                            </li>
                            <li class="nav-item" style="width: 70px;">
                                <a class="nav-link d-flex align-items-center me-3" href="javascript:void(0)"
                                    routerLink="/login">
                                    <i class="bx bx-user me-1"></i> Hesabım
                                </a>
                                <ul class="dropdown-menu" style="top: 54px;">
                                    <ng-container *ngIf="!isLogin">
                                        <li class="nav-item"><a routerLink="/login" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Giriş
                                                Yap</a></li>
                                        <li class="nav-item"><a routerLink="/register" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Üye
                                                Ol</a></li>
                                    </ng-container>
                                    <ng-container *ngIf="isLogin">
                                        <li class="nav-item"><a routerLink="/account" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Hesap
                                                Bilgileri</a></li>
                                        <li class="nav-item"><a routerLink="/customer-addresses" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Adreslerim</a></li>
                                        <li class="nav-item"><a routerLink="/orders" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Siparişlerim</a></li>
                                        <li class="nav-item"><a routerLink="/favorite" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Destek
                                                Kayıtları</a></li>
                                        <li class="nav-item"><a href="/" (click)="logOut()" class="nav-link">Çıkış
                                                Yap</a></li>
                                    </ng-container>
                                </ul>
                            </li>
                        </ul>

                    </div>
                    <!-- Collapsible wrapper -->
                </div>
                <!-- Container wrapper -->
            </nav>
            <!-- Navbar -->
            <nav class="navbar navbar-expand-lg  navbar-light bg-light">
                <!-- Container wrapper -->
                <div class="container">
                    <!-- Collapsible wrapper -->
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ms-3">
                            <li class="nav-item" *ngFor="let item of categoriesData">
                                <a class="nav-link d-flex align-items-center me-3"
                                    [routerLink]="['/ürünler',item.id,item.name]">
                                    {{item.name}}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!-- Collapsible wrapper -->
                </div>
                <!-- Container wrapper -->
            </nav>
        </div>
    </div>


    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <!-- <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div> -->
                    <ng-container *ngIf="isLogin">
                        <a routerLink="/account"><i class="bx bx-user me-1" style="font-size: x-large;"></i></a>
                    </ng-container>
                    
                    <ng-container *ngIf="!isLogin">
                        <a routerLink="/login"><i class="bx bx-user me-1" style="font-size: x-large;"></i></a>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- <div class="search-overlay-form">
    <form>
        <input type="text" class="input-search" placeholder="Ara...">
        <button type="submit"><i class='bx bx-search'></i></button>
    </form>
</div> -->