import { Component, OnInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { SiteInformationService } from 'src/app/services/siteInformation';
import { ContactInfoApiModel, ContactInfoModel } from 'src/app/models/siteInformationModel';
import { apiConst } from 'src/app/constants/apiConst';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class FooterComponent implements OnInit {
    contactInfoApiModel: ContactInfoApiModel;
    contactInfoData: ContactInfoModel

    constructor(private webSiteInfoService: SiteInformationService) { }

    ngOnInit(): void {

        this.webSiteInfoService.GetContactInfo(apiConst.userId).subscribe({
            next: datas => {
                this.contactInfoApiModel = datas.data;
                this.contactInfoData = this.contactInfoApiModel.contactInfoData
            }
        })
    }
}