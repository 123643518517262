import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { apiConst } from 'src/app/constants/apiConst';
import { optionMapConst } from 'src/app/constants/optionMapConst';
import { CustomerAddressApiModel, CustomerAddressModel, AddCustomerAddressResponseModel } from 'src/app/models/customermodel';
import { SiteInformationApiModel } from 'src/app/models/siteInformationModel';
import { AlertService } from 'src/app/services/alertService';
import { CustomerService } from 'src/app/services/customer.service';
import { StorageService } from 'src/app/services/reactiveStorageService';
import { SiteInformationService } from 'src/app/services/siteInformation';

@Component({
  selector: 'app-address-common',
  templateUrl: './address-common.component.html',
  styleUrls: ['./address-common.component.scss']
})
export class AddressCommonComponent implements OnInit {

  addressForm: FormGroup

  bannerPhotoString: string;
  customerAddressApiModel: CustomerAddressApiModel
  customerAddressData: CustomerAddressModel

  pageTitle = [
    {
      bgImage: 'assets/img/page-title-bg.jpg',
      title: 'Adreslerim'
    }
  ]

  constructor(private spinner: NgxSpinnerService, private _formBuilder: FormBuilder, private alertService: AlertService, private ractiveLocalStorage: StorageService, private customerService: CustomerService, private siteInformationService: SiteInformationService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.addressForm = this._formBuilder.group({
      addressName: ["", [Validators.required]],
      address: ["", [Validators.required]],
    })

    this.getCustomerAddressList();
    this.getCustomerPageDatas();
  }

  getCustomerAddressList() {
    this.customerService.CustomerAddressGetByCustomerId(this.ractiveLocalStorage.get(apiConst.customerId)).subscribe({
      next: datas => {
        this.customerAddressApiModel = datas.data;
        this.customerAddressData = this.customerAddressApiModel.customerAddressesData;
      }
    })
  }

  getCustomerPageDatas() {
    this.siteInformationService.GetSiteInformationByType({ type: [optionMapConst.address], userId: apiConst.userId }).subscribe({
      next: datas => {
        let siteInformationApiModel: SiteInformationApiModel
        siteInformationApiModel = datas.data;
        siteInformationApiModel.siteInformationsData[0].siteInformationPhotos.forEach((element) => {
          element.photoString = 'https://eirc.com.tr/assets/img/' + element.photoString;
        })
        this.bannerPhotoString = siteInformationApiModel.siteInformationsData[0].siteInformationPhotos[0].photoString;
        this.spinner.hide();
      }
    })
  }

  addCustomerAddress() {
    let customerAddressResponseModel: AddCustomerAddressResponseModel = { address: this.addressForm.value.address, addressName: this.addressForm.value.addressName, createdUserId: apiConst.userId, customerId: this.ractiveLocalStorage.get(apiConst.customerId), customerName: this.ractiveLocalStorage.get(apiConst.customerName) };
    this.customerService.AddCustomerAddress(customerAddressResponseModel).subscribe({
      next: response => {
        this.alertService.AddAlert(response)
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    })
  }


  deleteCustomerAddress(customerAddressId: string) {
    this.alertService.DeleteAlert("Adres").subscribe({
      next: response => {
        if (response) {
          this.customerService.CustomerAddressChangeStatus(customerAddressId).subscribe({
            next: response => {
              this.alertService.DeletedAlert(response);
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            }
          })
        }
      }
    })
  }
}
