import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: "En Son Haberlerimiz",
            paragraph: "Alışveriş Deneyiminizde Öncü Haberler. En Son Haberlerimizle, Trend Ürünleri ve Harika Fırsatları İlk Siz Keşfedin! E-Ticaret Dünyasının Nabzını Tutun, Yenilikleri Kaçırmayın."
        }
    ]
    singleBlogPost = [
        {
            img: 'assets/img/67e0e7a1-1e6a-4159-94d3-d4b4360db327.jpg',
            tag: 'Furniture',
            title: 'AL-KO manevra sistemi RANGER',
            paragraph: 'Maks. 1,8 t tek akslı karavanlara ve maks. 2,5 t tandem akslı karavanlara yönelik yeni AL-KO RANGER manevra sistemi karavanınızı tam da götürmek istediğiniz yöne kumanda eder ve manevra yapar.',
            linkText: 'Daha Fazla Oku',
            link: 'blog-details'
        },
        {
            img: 'assets/img/f62f2c2d-16c0-472e-9a31-dc6ff2ee89d6.jpg',
            tag: 'Sofa',
            title: 'Saphir Compact Yer Tipi Klima',
            paragraph: 'Dağıtım kanalları ile bulunduğunuz mekanın her köşesinde aynı konfor,Yakıt kullanmayan klima sistemi,Düşünebileceğiniz tüm kabinlerde kullanılabilir',
            linkText: 'Daha Fazla Oku',
            link: 'blog-details'
        },
        {
            img: 'assets/img/bac024b2-9667-4861-aa95-bfe701461edd.jpg',
            tag: 'Outdoor',
            title: 'Thule 4200 Kasetli Tente (2.60x2.00)',
            paragraph: 'Opsiyonel Thule Tension Rafterın kurulumu kolaydır, optimum kumaş gerginliği sağlar ve tentenin rüzgar direncini artırır',
            linkText: 'Daha Fazla Oku',
            link: 'blog-details'
        }
    ]

}