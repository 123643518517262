import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items || !searchText) {
            return items;
        }
        searchText = searchText.toLowerCase();

        // Filtreleme işlemi
        let filteredItems = items.filter(item => {
            return item.name.toLowerCase().includes(searchText);
        });

        // Filtrelenmiş elemanları alıp, önce gelenleri yeni diziye ekleriz
        let remainingItems = items.filter(item => !filteredItems.includes(item));

        // Filtrelenmiş elemanları, önce gelenlerin başına ekleriz
        return filteredItems.concat(remainingItems);
    }
}
