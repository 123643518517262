import { Component, OnInit } from '@angular/core';
import { apiConst } from 'src/app/constants/apiConst';
import { optionMapConst } from 'src/app/constants/optionMapConst';
import { SiteInformationApiModel } from 'src/app/models/siteInformationModel';
import { StorageService } from 'src/app/services/reactiveStorageService';
import { SiteInformationService } from 'src/app/services/siteInformation';

@Component({
    selector: 'app-my-account-page',
    templateUrl: './my-account-page.component.html',
    styleUrls: ['./my-account-page.component.scss']
})
export class MyAccountPageComponent implements OnInit {

    userLogin: boolean = false;
    selectedPageNumber: number = 0;
    bannerPhotoString: string;

    constructor(private siteInformationService: SiteInformationService, private localStorage: StorageService) { }

    ngOnInit(): void {
        this.getWebSiteInfoDatas();
        this.checkUserLogin();
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'Hesap Bilgileri'
        }
    ]

    checkUserLogin() {
        let login = this.localStorage.get(apiConst.customerId)
        if (login != null) {
            this.userLogin = true;
        } else {
            this.userLogin = false;
        }
    }

    getWebSiteInfoDatas() {
        this.siteInformationService.GetSiteInformationByType({ type: [optionMapConst.accountInfo], userId: apiConst.userId }).subscribe({
            next: datas => {
                let siteInformationApiModel: SiteInformationApiModel
                siteInformationApiModel = datas.data;
                siteInformationApiModel.siteInformationsData[0].siteInformationPhotos.forEach((element) => {
                    element.photoString = 'https://eirc.com.tr/assets/img/' + element.photoString;
                })
                this.bannerPhotoString = siteInformationApiModel.siteInformationsData[0].siteInformationPhotos[0].photoString;
            }
        })
    }

    changeSelectedPage(event: MouseEvent, pageNumber: number) {
        event.preventDefault(); // Varsayılan davranışı engelle
        console.log("page Number  " + pageNumber)
        this.selectedPageNumber = pageNumber
    }


    logOut() {
        this.localStorage.remove(apiConst.customerId)
        this.localStorage.remove(apiConst.customerName)
    }
}