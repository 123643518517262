<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1>{{Content.title}}</h1>
    <ul>
        <li><a routerLink="/">Anasayfa</a></li>
        <li>{{Content.title}}</li>
    </ul>
</div>
<!-- End Page Title Area -->

<!-- Start Cart Area -->
<section class="cart-area ptb-100">
    <div class="container">
        <form>
            <div class="cart-table table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Sipariş Adı</th>
                            <th scope="col">Toplam Fİyat</th>
                            <th scope="col">REFERANS KODU</th>
                            <th scope="col">SİPARİŞ DURUMU</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let item of orderModelList">
                            <td class="product-name">
                                <a>{{item.name}}</a>
                            </td>
                            <td class="product-price">
                                <span class="unit-amount">{{item.totalPrice}}</span>
                            </td>
                            <td class="product-quantity">
                                <span class="unit-amount">{{item.refferenceCode}}</span>
                            </td>
                            <td class="product-subtotal">
                                <span class="subtotal-amount">{{item.situationName}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>
    </div>
</section>
<!-- End Cart Area -->