import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { apiConst } from 'src/app/constants/apiConst';
import { CustomerModel, CustomerData } from 'src/app/models/customermodel';
import { CustomerService } from 'src/app/services/customer.service';
import { StorageService } from 'src/app/services/reactiveStorageService';
import { SiteInformationService } from 'src/app/services/siteInformation';

@Component({
  selector: 'app-password-update',
  templateUrl: './password-update.component.html',
  styleUrls: ['./password-update.component.scss']
})
export class PasswordUpdateComponent {
  //çerezlere eklenen customerId ile ilerlenicek
  public registerForm: FormGroup;


  constructor(
    private _fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.registerForm = this._fb.group({
      password: ["", [Validators.required]],
      confirmPassword: ['', Validators.required],
    }, {
      validator: this.passwordMatchValidator
    })
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password').value;
    const confirmPassword = formGroup.get('confirmPassword').value;

    if (password === confirmPassword) {
      return null; // Şifreler eşleşiyorsa hata yok
    } else {
      return { passwordMismatch: true }; // Hata nesnesi döndür
    }
  }

  passwordUpdate() {
    console.log("pressed button to register")
  }

}
