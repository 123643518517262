import { Component, OnInit } from '@angular/core';
import { AddOrderModel, OrderApiModel } from 'src/app/models/orderModel';
import { OrderService } from 'src/app/services/orderService';

@Component({
  selector: 'app-orders-page',
  templateUrl: './orders-page.component.html',
  styleUrls: ['./orders-page.component.scss']
})
export class OrdersPageComponent implements OnInit {

  orderModelApi: OrderApiModel;
  orderModelList: AddOrderModel[];

  constructor(private orderService: OrderService) { }

  ngOnInit(): void {
    this.getCustomerOrderList();
  }

  getCustomerOrderList() {
    this.orderService.OrderGetByCustomerId().subscribe({
      next: datas => {
        this.orderModelApi = datas.data;
        this.orderModelList = this.orderModelApi.ordersData
      } 
    })
  }

  pageTitle = [
    {
      bgImage: 'assets/img/page-title-bg.jpg',
      title: 'Siparişlerim'
    }
  ]
}
