import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { apiConst } from 'src/app/constants/apiConst';
import { optionMapConst } from 'src/app/constants/optionMapConst';
import { ContactInfoApiModel, ContactInfoModel, SiteInformation, SiteInformationApiModel } from 'src/app/models/siteInformationModel';
import { SiteInformationService } from 'src/app/services/siteInformation';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
    mapUrl: SafeResourceUrl;

    contactInfoApiModel: ContactInfoApiModel;
    contactInfoData: ContactInfoModel

    siteInformationApiModel: SiteInformationApiModel
    siteInformationList: SiteInformation[] = [];
    photoString: string;
    pageText: string;

    constructor(private webSiteInfoService: SiteInformationService, private sanitizer: DomSanitizer, private spinner: NgxSpinnerService, private siteInformationService: SiteInformationService) { }

    ngOnInit(): void {
        this.siteInformationService.GetSiteInformationByType({ type: [optionMapConst.returnPolicy], userId: apiConst.userId }).subscribe({
            next: datas => {
                let siteInformationApiModel: SiteInformationApiModel
                siteInformationApiModel = datas.data;
                this.pageText = siteInformationApiModel?.siteInformationsData[0]?.siteInformationPhotos[0]?.text;
                this.photoString = siteInformationApiModel.siteInformationsData[0].siteInformationPhotos[0].photoString;
                this.photoString = apiConst.imageUrl + this.photoString;

            }
        })
  
        this.spinner.show();
        this.webSiteInfoService.GetContactInfo(apiConst.userId).subscribe({
            next: datas => {
                this.contactInfoApiModel = datas.data;
                this.contactInfoData = this.contactInfoApiModel.contactInfoData
                this.mapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.contactInfoData.mapUrl);
                this.spinner.hide();
            }
        })
    }

    pageTitle = [
        {
            bgImage: 'assets/img/page-title-bg.jpg',
            title: 'İletişim'
        }
    ]

    submit(form) {
        var name = form.name;
        console.log(name);

        var email = form.email;
        console.log(email);

        var number = form.number;
        console.log(number);

        var message = form.message;
        console.log(message);
    }

}