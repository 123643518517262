<div class="login-form">
    <h2 class="text-center">Hesap Bilgileri</h2>
    <form [formGroup]="registerForm" (ngSubmit)="register()">
        <div class="form-group">
            <label>Ad</label>
            <input formControlName="firstName" type="text" class="form-control" placeholder="Ad"
                [ngClass]="{'error': registerForm.get('firstName').invalid && (registerForm.get('firstName').dirty || registerForm.get('firstName').touched)}">
            <div class="mt-3 text-danger"
                *ngIf="registerForm.get('firstName').hasError('required') && (registerForm.get('firstName').dirty || registerForm.get('firstName').touched)">
                Ad alanı boş bırakılamaz.
            </div>
        </div>
        <div class="form-group">
            <label>Soyad</label>
            <input formControlName="lastName" type="text" class="form-control" placeholder="Soyad"
                [ngClass]="{'error': registerForm.get('lastName').invalid && (registerForm.get('lastName').dirty || registerForm.get('lastName').touched)}">
            <div class="mt-3 text-danger"
                *ngIf="registerForm.get('lastName').hasError('required') && (registerForm.get('lastName').dirty || registerForm.get('lastName').touched)">
                Soyad alanı boş bırakılamaz.
            </div>
        </div>
        <div class="form-group">
            <label>E-mail</label>
            <input formControlName="mailAddress" type="email" class="form-control" placeholder="E-mail"
                [ngClass]="{'error': registerForm.get('mailAddress').invalid && (registerForm.get('mailAddress').dirty || registerForm.get('mailAddress').touched)}">
            <div class="mt-3 text-danger"
                *ngIf="registerForm.get('mailAddress').hasError('required') && (registerForm.get('mailAddress').dirty || registerForm.get('mailAddress').touched)">
                E-mail alanı boş bırakılamaz.
            </div>
        </div>

        <div class="form-group">
            <label>Telefon</label>
            <input formControlName="phoneNumber" type="text" class="form-control" placeholder="Telefon"
                [ngClass]="{'error': registerForm.get('phoneNumber').invalid && (registerForm.get('phoneNumber').dirty || registerForm.get('phoneNumber').touched)}">
            <div class="mt-3 text-danger"
                *ngIf="registerForm.get('phoneNumber').hasError('required') && (registerForm.get('phoneNumber').dirty || registerForm.get('phoneNumber').touched)">
                Telefon alanı boş bırakılamaz.
            </div>
        </div>
        <button type="submit" disabled>Güncelle</button>
    </form>
</div>