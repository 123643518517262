import { Component, OnInit } from '@angular/core';
import { apiConst } from 'src/app/constants/apiConst';
import { optionMapConst } from 'src/app/constants/optionMapConst';
import { SiteInformation, SiteInformationApiModel, SiteInformationRequestModel } from 'src/app/models/siteInformationModel';
import { SiteInformationService } from 'src/app/services/siteInformation';

@Component({
    selector: 'app-about-page',
    templateUrl: './about-page.component.html',
    styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {
    siteInformationApiModel: SiteInformationApiModel
    siteInformationList: SiteInformation[] = [];

    constructor(private siteInformationService: SiteInformationService) { }

    ngOnInit(): void {
        let siteInformationRequestModel: SiteInformationRequestModel = { type: [optionMapConst.aboutUs], userId: apiConst.userId }
        this.siteInformationService.GetSiteInformationByType(siteInformationRequestModel).subscribe({
            next: datas => {
                this.siteInformationApiModel = datas.data;
                this.siteInformationList = this.siteInformationApiModel.siteInformationsData
                this.siteInformationList[0].siteInformationPhotos[0].photoString = "https://eirc.com.tr/assets/img/"+     this.siteInformationList[0].siteInformationPhotos[0].photoString
            }
        })
    }
}