<!-- Start Partner Area -->
<section class="partner-area ptb-70 bg-f5f5f5">
    <div class="container">
        <div class="partner-slides">
            <owl-carousel-o [options]="partnerSlidesOptions">
                <ng-template carouselSlide *ngFor="let item of brandsData;">
                    <div class="partner-item">
                        <div class="row">
                            <a href="#" target="_blank">
                                <img src="assets/img/partner/partner1.png" style="max-width: 100px;max-height: 100px;" alt="image">
                            </a>
                        </div>
                        <div class="row text-center">
                            <b>{{item.name}}</b>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>
<!-- End Partner Area -->