import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { apiConst } from 'src/app/constants/apiConst';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public registerForm: FormGroup;
  constructor(
    private _fb: FormBuilder,
    private customerService: CustomerService,
    private toastrService: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.registerForm = this._fb.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      customerName: ["", Validators.required],
      password: ["", [Validators.required]],
      confirmPassword: ['', Validators.required],
      mailAddress: ["", [Validators.required]],
      phoneNumber: ["", [Validators.required]]
    }, {
      validator: this.passwordMatchValidator
    })
  }


  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password').value;
    const confirmPassword = formGroup.get('confirmPassword').value;

    if (password === confirmPassword) {
      return null; // Şifreler eşleşiyorsa hata yok
    } else {
      return { passwordMismatch: true }; // Hata nesnesi döndür
    }
  }

  register() {
    const firstName = this.registerForm.get('firstName').value;
    const lastName = this.registerForm.get('lastName').value;

    this.registerForm.get('customerName').setValue(firstName + ' ' + lastName);
      this.customerService.Register(this.registerForm.value).subscribe(response => {
        if (response.isSuccess) {
          this.toastrService.success(response.statusMessage, "Başarılı")
          this.router.navigate(['/login'])
        } else {
          this.toastrService.error(response.statusMessage, "Başarısız")
        }
      })
  }
}
